import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { ListItem, List } from '@material-ui/core/'

export interface SelectActionProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	getTplUnnormalizedСosts(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
	uploaderUnnormalizedСosts(event: React.ChangeEvent<HTMLInputElement>): void
	getUnnormalizedСosts(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
	deleteUnnormalizedСosts(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
	t7Id: string | null
}

const SelectAction: React.FC<SelectActionProps> = ({
	anchorEl,
	setAnchorEl,
	getTplUnnormalizedСosts,
	uploaderUnnormalizedСosts,
	getUnnormalizedСosts,
	deleteUnnormalizedСosts,
	t7Id,
}) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ borderRadius: 9, width: 300 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<>
				<List className='drop-down__list' component='nav' aria-label='main mailbox folders'>
					<ListItem button className='drop-down-item' onClick={getTplUnnormalizedСosts}>
						<span className='download-template-icon'></span>
						1.Получить шаблон
					</ListItem>
					<ListItem button className='drop-down-item'>
						<label className='flex pointer'>
							<input
								type='file'
								accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								onChange={uploaderUnnormalizedСosts}
								className='check-plate__uploader'
							/>
							<span className='btn-uploader-icon'></span>
							2.Загрузить заполненный шаблон
						</label>
					</ListItem>
					<ListItem button disabled={(t7Id)?false:true} className='drop-down-item' onClick={getUnnormalizedСosts}>
						<span className='download-file-icon '></span>
						3.Скачать файл
					</ListItem>
					<ListItem button disabled={(t7Id)?false:true} className='drop-down-item'>
						<button className='flex pointer' onClick={deleteUnnormalizedСosts}>
							<span className='delete-icon'></span>
							4.Удалить
						</button>
					</ListItem>
				</List>
			</>
		</DropDown>
	)
}
export default SelectAction
