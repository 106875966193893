/* eslint-disable no-useless-escape */
import React, { useMemo, useState, useEffect, useContext, useCallback, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/Select'
import { CalendarInterval } from './CalendarInterval'
import { Carousel } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/Carousel'
import { CarouselWide } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/CarouselWide'
import {
	setProjectCode,
	setProjectName,
	setProjectYears,
	setProjectRegions,
	setProjectValuation,
	setProjectAdditional,
	setProjectRationale,
	setProjectActual,
	setProjectNds,
	updateDeflators,
	getCheckedErrors,
	downloadProject,
	copyProject,
	getTplUnnormalizedСosts,
	getUnnormalizedСosts,
	uploaderUnnormalizedСosts,
	deleteUnnormalizedСosts,
} from '@root-gipro/modules/userProjects/store/actions'
import { saveUserProject, deleteUserProject } from '@root-gipro/modules/userProjects/store/actions'
import { SelectAction } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { DeleteProjectWindow, CheckProjectWindow, SelectF20Window } from '@root-gipro/modules/userProjects/components/Project/Modal'
import { prettyCost, intFormat } from '@root-gipro/utils/helpers/common'
import { IState } from '@root-gipro/store/interfaces/state'
import { IRegion } from '@root-gipro/store/interfaces'
import { ModalContext } from '@root-gipro/context/modal-context'
import { IconButton } from '@material-ui/core'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { Input } from '@root-gipro/shared/FormElements'
import '@root-gipro/modules/userProjects/styles/CheckPlate.scss'

const CheckPlate: FC = () => {
	const { projectObj, sectionIds } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)
	const { accessory, setAccessory, accessoryRef, setAnchorEl, anchorEl } = useContext(ModalContext)
	const [defaultRegions, setDefaultRegions] = useState<IRegion[]>([])
	const sectionIdsArr: number[] = sectionIds.map(item => Number(item.id))
	const dispatch = useDispatch()

	const years = useMemo(() => {
		let years = []
		if (projectObj && projectObj.years) {
			years = Object.keys(projectObj.years)
			return years.map(year => [year, projectObj.years[year]?.offer])
		}
	}, [projectObj])

	const deflators = useMemo(() => {
		let years = []
		if (projectObj && projectObj.years) {
			years = Object.keys(projectObj.years)
			return years.map(year => [year, projectObj.years[year]?.deflator])
		}
	}, [projectObj])

	const currentYear = new Date().getFullYear()
	const defaultRegionName: string = 'Москва'
	const defaultRegionId = useMemo(() => {
		return regions
			.filter(region => {
				if (region.searchName === defaultRegionName) {
					return region.id
				}
			})
			.map(i => i.id)
	}, [regions])

	const handlerClickDeletePopup = () => {
		dispatch(deleteUserProject(projectObj.id!, projectObj.code))
		setAnchorEl!(null!)
	}
	const handlerClickDownloadF20Popup1 = () => {
		dispatch(downloadProject(projectObj.id!, projectObj.code, 'base'))
		setAnchorEl!(null!)
	}
	const handlerClickDownloadF20Popup2 = () => {
		dispatch(downloadProject(projectObj.id!, projectObj.code, 'new'))
		setAnchorEl!(null!)
	}
	const handlerClickSave = () => {
		if (!projectObj.id) {
			dispatch(saveUserProject(projectObj, true))
		} else {
			let clone = Object.assign({}, projectObj)
			delete clone.id
			delete clone.userId
			dispatch(saveUserProject(clone, false, projectObj.id))
		}
	}
	const openWindow = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			setAnchorEl!(e.currentTarget)
			setAccessory!('check-list')
			if (accessoryRef) accessoryRef.current = accessory
		},
		[setAnchorEl]
	)

	useEffect(() => {
		if (!projectObj.id) {
			dispatch(setProjectYears(currentYear, currentYear))
			dispatch(updateDeflators(currentYear, currentYear))
		}
	}, [])

	useEffect(() => {
		if (!projectObj.id) dispatch(setProjectRegions(defaultRegionId))
	}, [defaultRegionId, projectObj.id, setProjectRegions])

	useEffect(() => {
		const regionsIdsString = projectObj.regionIds.map((i: string) => i.toString())
		const getRegions = regions.filter((region: IRegion) => regionsIdsString.includes(region.id))

		setDefaultRegions(getRegions)
	}, [projectObj.regionIds, regions])

	return (
		<div className='container'>
			<div className='check-plate'>
				<div className='check-plate-row flex flex-between'>
					<div className='check-plate-row-left flex'>
						{/* <span className='user-projects-icon'></span> */}
						<input
							className='code check-plate-input'
							type='text'
							value={projectObj.code || ''}
							placeholder='Идентификатор'
							onChange={e => dispatch(setProjectCode(e.currentTarget.value.replace(/[^\S]/, '')))}
							onBlur={e => (e.target.value.trim() === '' ? e.target.classList.add('input-no-valid') : null)}
						/>
					</div>
					{projectObj.id && (
						<div className='check-plate-row-right'>
							<div className='user-projects__options'>
								<IconButton data-popup='select-f20' onClick={openWindow}>
									<span className='download-icon' title='Скачать'></span>
								</IconButton>
								{/*
								<IconButton onClick={() => dispatch(downloadProject(projectObj.id!, projectObj.code))}>
									<span className='download-icon'></span>
								</IconButton>
								*/}
								<IconButton onClick={() => dispatch(copyProject(projectObj.id!, `${projectObj.code}_COPY`))}>
									<span className='copy-icon' title='Копировать'></span>
								</IconButton>
								<IconButton data-popup='del-project' onClick={openWindow}>
									<span className='delete-icon' title='Удалить'></span>
								</IconButton>
								{anchorEl?.dataset.popup === 'del-project' && (
									<DeleteProjectWindow
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl!}
										handler={handlerClickDeletePopup}
									/>
								)}
								{anchorEl?.dataset.popup === 'select-f20' && (
									<SelectF20Window
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl!}
										handler1={handlerClickDownloadF20Popup1}
										handler2={handlerClickDownloadF20Popup2}
									/>
								)}
							</div>
						</div>
					)}
				</div>
				<div className='check-plate-row row-aria'>
					<textarea
						placeholder='Наименование инвестиционного объекта'
						value={projectObj.name || ''}
						onChange={e => dispatch(setProjectName(e.currentTarget.value))}
						className='check-plate-textarea'
					/>
					<textarea
						onChange={e => dispatch(setProjectRationale(e.currentTarget.value))}
						spellCheck='false'
						value={projectObj.offerRationale || ''}
						placeholder='Комментарии'
						className='check-plate-textarea'
					/>
				</div>
				<div className='check-plate-row row-cell'>
					<div className='cell'>
						<div className='cell-label'>Регион поставки</div>
						<Select
							list={regions}
							placeholder='Выберете'
							tooltip='Субъекты РФ реализации проекта'
							multy={true}
							changeHandler={val => {
								let regionIds = val.map((region: IRegion) => region.id)
								dispatch(setProjectRegions(regionIds))
							}}
							defaultVal={defaultRegions}
						/>
					</div>
					<div className='cell'>
						<div className='cell-label'>Период реализации</div>
						<CalendarInterval
							placeholder='Период реализации'
							changeHandler={(value: number[]) => {
								dispatch(setProjectYears(value[0], value[1]))
								if (value[0] && value[1]) {
									dispatch(updateDeflators(value[0], value[1]))
								}
							}}
							defaultVal={[projectObj.yearBegin, projectObj.yearEndOffer]}
							wrapClass='no_padding year_interval'
							tooltip='Период реализации'
							format='yyyy'
							views={['year']}
							dateCorrectLength={4}
							min={projectObj.yearBegin ? projectObj.yearBegin : '1985'}
							max={projectObj.yearEndOffer ? projectObj.yearEndOffer : '2050'}
						/>
					</div>
					<div className='cell'>
						<div className='cell-label'>Факт. объем финансирования (с НДС)</div>
						<Input
							inputValue={projectObj.actualFunding ? projectObj.actualFunding / 10 ** 6 : '0'}
							placeholderValue='Введите'
							changeFunc={(value: string) => {
								dispatch(setProjectActual(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
						>
							<span className='currency'>млн.р</span>
						</Input>
					</div>
					<div className='cell'>
						<div className='cell-label'>Оценка полной стоимости в прогнозных ценах соотв. лет (с НДС)</div>
						<Input
							inputValue={projectObj.valuationOfferForecast ? projectObj.valuationOfferForecast / 10 ** 6 : '0'}
							placeholderValue='Введите'
							changeFunc={(value: string) => {
								dispatch(setProjectValuation(+value * 10 ** 6))
							}}
							InputIntShow={true}
							classes='full-width'
						>
							<span className='currency'>млн.р</span>
						</Input>
					</div>
					<div className='cell flex '>
						<div className='cell-item-left'>
							<div className='cell-label'>Сумма ненормируемых затрат</div>
							<Input
								inputValue={projectObj.additionalFundingOffer ? projectObj.additionalFundingOffer / 10 ** 6 : '0'}
								placeholderValue='Введите'
								changeFunc={(value: string) => {
									dispatch(setProjectAdditional(+value * 10 ** 6))
								}}
								InputIntShow={true}
								classes='full-width'
							>
								<span className='currency'>млн.р</span>
							</Input>
						</div>
						<div className='cell-item-right'>
							<div className='h-32'></div>
							<div
								className='h-32 pointer flex justify-content-center'
								data-popup='select-an-action'
								onClick={e => setAnchorEl!(e.currentTarget)}
							>
								<div className='file_icon'></div>
								<span className='cell-file'>Файл</span>
							</div>
							{anchorEl?.dataset.popup === 'select-an-action' && (
								<SelectAction
									anchorEl={anchorEl!}
									setAnchorEl={setAnchorEl!}
									getTplUnnormalizedСosts={() => dispatch(getTplUnnormalizedСosts(projectObj.id!))}
									uploaderUnnormalizedСosts={e => dispatch(uploaderUnnormalizedСosts(e.target.files, projectObj.id!))}
									getUnnormalizedСosts={() => dispatch(getUnnormalizedСosts(projectObj.id!))}
									deleteUnnormalizedСosts={() => dispatch(deleteUnnormalizedСosts(projectObj.id!))}
									t7Id={projectObj.t7Id!}
								/>
							)}
						</div>
					</div>

					<div className='cell'>
						<div className='cell-label'>Финансирование по годам</div>
						<Carousel
							code='offer'
							numberTransformation={true}
							dataArr={years}
							totalInvestmentUncOffer={projectObj.totalInvestmentUncOffer && projectObj.totalInvestmentUncOffer}
							title='Объем финансирования по годам, млн.р (С НДС)'
						/>
					</div>
					{/*
					<div className='cell'>
						<div className='cell-label'>Индексы дефляторы</div>
						<Carousel code='deflator' dataArr={deflators} title='Индексы дефляторы' />
					</div>
					*/}
					<div className='cell'>
						<div className='cell-label'>Индексы дефляторы</div>
						<CarouselWide code='deflator' dataArr={deflators} title='Индексы дефляторы' />
					</div>

					<div className='cell'>
						<div className='cell-label'>Объем финансовых потребностей по УНЦ в прогнозных ценах (с НДС)</div>
						<div className='cell__cost-info'>
							{projectObj.totalInvestmentUncOffer || projectObj.totalInvestmentUncOffer === 0 ? (
								<>
									<div> {`${intFormat(projectObj.totalInvestmentUncOffer / 10 ** 6, true)}`} </div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</div>

					<div className='cell'>
						<div className='cell-label'>Итого ОФП по УНЦ в текущих ценах (с НДС)</div>
						<div className='cell__cost-info'>
							{projectObj.sumUncCostWithNds || projectObj.sumUncCostWithNds === 0 ? (
								<>
									<div> {`${prettyCost(projectObj.sumUncCostWithNds / 10 ** 6)}`} </div>
									<span>млн.р</span>
								</>
							) : (
								<span className='no_cost'>Значение отсутствует</span>
							)}
						</div>
					</div>
					<div className='cell flex'>
						<div className='cell-item-left'>
							<div className='cell-label'>Итого ОФП по УНЦ</div>
							<div className='cell__cost-info'>
								{projectObj.sumUncCost || projectObj.sumUncCost === 0 ? (
									<>
										<div> {`${prettyCost(projectObj.sumUncCost / 10 ** 6)}`} </div>
										<span>млн.р</span>
									</>
								) : (
									<span className='no_cost'>Значение отсутствует</span>
								)}
							</div>
						</div>
						<div className='cell-item-right'>
							<div className='cell-label'>НДС,%</div>
							<input
								type='text'
								onChange={e => {
									let val = e.currentTarget.value
									val = val.replace(/[^0-9\.\,]/, '').replace(/\,/, '.')
									dispatch(setProjectNds(+val / 100))
								}}
								value={projectObj.nds ? projectObj.nds * 100 : ''}
								className='check-plate-input'
							/>
						</div>
					</div>
				</div>
				<div className='wrapper-controls flex'>
					<div className='checkproject_wrapper'>
						<PrimaryBtn onClick={handlerClickSave}>Сохранить</PrimaryBtn>
						{anchorEl?.dataset.popup === 'check-project' && (
							<CheckProjectWindow
								setAnchorEl={setAnchorEl!}
								anchorEl={anchorEl}
								sectionIds={sectionIds}
								sectionIdsArr={sectionIdsArr}
								setAccessory={setAccessory}
								accessory={accessory}
								accessoryRef={accessoryRef}
								handler={() => {
									setAccessory!('error-list')
									if (accessoryRef) accessoryRef.current = accessory
									dispatch(getCheckedErrors(projectObj.id, sectionIdsArr))
								}}
							/>
						)}
					</div>
					<div className='ml-8'>
						<SecondaryBtn onClick={openWindow} data-popup='check-project'>
							Проверить
						</SecondaryBtn>
					</div>
				</div>
			</div>
		</div>
	)
}

export { CheckPlate }
