import React, { useEffect, useState } from 'react'
import { FilterItemProps } from '@root-gipro/shared/Filter/models/filter-item.props'
import FilterItemInput from '@root-gipro/shared/Filter/FilterItem/FilterItemInput'
import FilterItemCheckbox from '@root-gipro/shared/Filter/FilterItem/FilterItemCheckbox'
import FilterItemSelect from '@root-gipro/shared/Filter/FilterItem/FilterItemSelect'
import FilterItemRangeNumbers from '@root-gipro/shared/Filter/FilterItem/FilterItemRangeNumbers'
import FilterItemDateRange from '@root-gipro/shared/Filter/FilterItem/FilterItemDateRange'
import { formatDate } from '@root-gipro/utils/helpers/common'

import FilterItemSelectSearch from './FilterItemSelectSearch'
import FilterItemSearchCheckboxes from './FilterSearchCheckboxes'
import FilterSearchRadio from './FilterSearchRadio'
import FilterItemSearchCheckboxesUnc from './FilterSearchCheckboxesUnc'

const FilterItem: React.FC<FilterItemProps> = ({
	name = '',
	mode,
	filterState,
	iconName,
	isDisable,
	fieldName = '',
	setValue,
	minMax,
	items,
	withCheckbox,
	isMultiple,
	placeholder,
	fieldNameEnd = '',
	fieldNameStart = '',
	dateFormat,
	dateViews,
	dateOpenTo,
	convertValue,
	disableCloseOnSelect,
}) => {
	if (mode === 'select') {
		if (!items) {
			throw Error('items not specified into filter select')
		}
	}

	if (!['range-numbers', 'dates-range'].includes(mode) && !fieldName) {
		throw Error(`fieldName not specified into filter item: ${mode}`)
	}

	if (['range-numbers', 'dates-range'].includes(mode) && (!fieldNameStart || !fieldNameEnd)) {
		throw Error(`fieldName not specified into filter item: ${mode}`)
	}

	switch (mode) {
		case 'input':
			return (
				<FilterItemInput
					value={filterState[fieldName]}
					setValue={setValue}
					inputName={fieldName}
					labelName={name}
					iconName={iconName}
					disabled={isDisable}
					handleBlur={() => setValue}
					clearState={() => setValue}
				/>
			)
		case 'checkbox':
			return (
				<FilterItemCheckbox
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
				/>
			)
		case 'select':
			return (
				<FilterItemSelect
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
					placeholder={placeholder}
					items={items || []}
					withCheckbox={withCheckbox}
					isMultiple={isMultiple}
					convertValue={convertValue}
					disableCloseOnSelect={disableCloseOnSelect}
				/>
			)
		case 'search-checkbox':
			return (
				<FilterItemSearchCheckboxes
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
					placeholder={placeholder}
					items={items || []}
					withCheckbox={withCheckbox}
					isMultiple={isMultiple}
					convertValue={convertValue}
					disableCloseOnSelect={disableCloseOnSelect}
				/>
			)
		case 'search-checkbox-unc':
			return (
				<FilterItemSearchCheckboxesUnc
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
					placeholder={placeholder}
					items={items || []}
					withCheckbox={withCheckbox}
					isMultiple={isMultiple}
					convertValue={convertValue}
					disableCloseOnSelect={disableCloseOnSelect}
				/>
			)
		case 'search-radio':
			return (
				<FilterSearchRadio
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
					placeholder={placeholder}
					items={items || []}
					withCheckbox={withCheckbox}
					isMultiple={isMultiple}
					convertValue={convertValue}
					disableCloseOnSelect={disableCloseOnSelect}
				/>
			)
		case 'select-search':
			return (
				<FilterItemSelectSearch
					value={filterState[fieldName]}
					setValue={setValue}
					name={name}
					isDisable={isDisable}
					fieldName={fieldName}
					placeholder={placeholder}
					items={items || []}
				/>
			)
		case 'range-numbers':
			return (
				<FilterItemRangeNumbers
					name={name}
					setStart={value => setValue(fieldNameStart || '', value)}
					setEnd={value => setValue(fieldNameEnd || '', value)}
					end={filterState[fieldNameEnd]}
					start={filterState[fieldNameStart]}
					disabledAll={isDisable}
					fieldNameStart={fieldNameStart}
					fieldNameEnd={fieldNameEnd}
					minMax={minMax}
					withCheckbox={withCheckbox}
					convertValue={convertValue}
				/>
			)
		case 'dates-range':
			return (
				<FilterItemDateRange
					name={name}
					setStart={value => setValue(fieldNameStart || '', formatDate(value, true))}
					setEnd={value => setValue(fieldNameEnd || '', formatDate(value, true))}
					end={filterState[fieldNameEnd]}
					start={filterState[fieldNameStart]}
					disabledAll={isDisable}
					fieldNameStart={fieldNameStart}
					fieldNameEnd={fieldNameEnd}
					minMax={minMax}
					withCheckbox={withCheckbox}
					format={dateFormat}
					views={dateViews}
					openTo={dateOpenTo}
				/>
			)
		default:
			return <></>
	}
}
export default FilterItem
