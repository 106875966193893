import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { Checkbox, ListItemText, Slider } from '@material-ui/core'
import { spaceFormat } from '@root-gipro/utils/helpers/helperInputFloat'
import { isEqual } from 'lodash'
import { FilterItemRangePriceProps } from '@root-gipro/shared/Filter/models/filter-item-range-price.props'
import { Input } from '@root-gipro/shared/FormElements'
import { intFormat } from '@root-gipro/utils/helpers/common'

const FilterItemRangeNumbers: React.FC<FilterItemRangePriceProps> = ({
	setStart,
	setEnd,
	start,
	end,
	name,
	fieldNameStart,
	fieldNameEnd,
	minMax = { min: 0, max: 9000000000 },
	startName = 'с',
	endName = 'по',
	disabledAll,
	withCheckbox,
	convertValue,
}) => {
	const resetFields = () => {
		if (start || end) {
			setStart('')
			setEnd('')
		}
	}

	let minValueRange = 0
	let maxValueRange = 900000000
	if (fieldNameStart !== 'unc.count.min') {
		minValueRange = minMax[fieldNameStart.replace('.min', '')]
			? Math.round(Number(minMax[fieldNameStart.replace('.min', '')].min))
			: 0
		maxValueRange = minMax[fieldNameEnd.replace('.max', '')]
			? Math.round(Number(minMax[fieldNameEnd.replace('.max', '')].max))
			: 9000000000
	}

	const [startPrice, setStartPrice] = useState(start || '')
	const [endPrice, setEndPrice] = useState(end || '')

	const handlerFocusField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = intFormat(e.currentTarget.value)
		// setFn(value)
	}

	const handlerChangeField = (e: ChangeEvent<HTMLInputElement>, setFn: FunctionStringCallback, type: string) => {
		if ((type = 'start')) {
			setValue([Number(e.currentTarget.value.replace(/\s/g, '')), Number(endPrice)] as number[])
		}
		if ((type = 'end')) {
			setValue([Number(endPrice), Number(e.currentTarget.value.replace(/\s/g, ''))] as number[])
		}
		let value = intFormat(e.currentTarget.value)
		setFn(value)
	}

	const handlerBlurField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		const value = spaceFormat(e.currentTarget.value)
		// setFn(value)
	}

	useEffect(() => {
		if (!end && !start && fieldNameStart === 'unc.count.min') {
			setStart('')
			setEnd('')
		} else if (!end && !start) {
			setStart(minValueRange.toString())
			setEnd(maxValueRange.toString())
			setValue([minValueRange, maxValueRange])
		}
		if (!isEqual(start, startPrice)) {
			setStartPrice(start || '')
			// setFn(startPrice)
		}
		if (!isEqual(end, endPrice)) {
			setEndPrice(end || '')
		}
	}, [start, end])

	const [value, setValue] = React.useState<number[]>([minValueRange, maxValueRange])

	const handleChange = (event: any, newValue: any) => {
		setStartPrice(newValue[0].toString())
		setEndPrice(newValue[1].toString())
		setValue(newValue as number[])
	}
	const handleChangeCommitted = (event: any, newValue: any) => {
		setStart(newValue[0].toString())
		setEnd(newValue[1].toString())
	}

	return (
		<div className='range-price'>
			<div className='range-price__head'>
				<h5 className='range-price__head-title'>{name === 'Количество' ? name : 'Стоимость'}</h5>
				<span className='range-price__head-title link link-grey' onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<div className='range-price__duration'>
				<Input
					inputValue={intFormat(startPrice)}
					disabled={disabledAll || withCheckbox?.checked}
					// onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setStart)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setStart, 'start')}
					// handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setStart)}
					classes='part-width'
				></Input>
				<div>—</div>
				<Input
					inputValue={intFormat(endPrice)}
					disabled={disabledAll || withCheckbox?.checked}
					// onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setEnd)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setEnd, 'end')}
					// handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setEnd)}
					classes='part-width'
				></Input>
			</div>
			{fieldNameStart !== 'unc.count.min' ? (
				<Slider
					value={value}
					onChange={handleChange}
					onChangeCommitted={handleChangeCommitted}
					min={minValueRange}
					max={maxValueRange}
				/>
			) : null}
		</div>
	)
}

export default FilterItemRangeNumbers
