import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { HeaderNav, HeaderAvatar } from '@root-gipro/modules/header'
import { headerTheme } from '@root-gipro/styles/themes'
import { Routes } from '@root-gipro/router'
import { IState } from '@root-gipro/store/interfaces/state'
import { logout } from '@root-gipro/store/actions/auth'
import styles from '@root-gipro/modules/header/styles/Header.module.scss'
import { setAllowMoreProjects } from '@root-gipro/modules/commonProjects/store/actions'

const HeaderContainer: FC = () => {
	// const { policesGipro } = useSelector((state: IState) => state.public)
	const { allowMoreProjects } = useSelector((state: IState) => state.commonProjects)
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const role = Number(window.localStorage.getItem('roleId'))

	useEffect(() => {
		if (!allowMoreProjects) {
			dispatch(setAllowMoreProjects(true))
		}
	}, [pathname])

	return (
		<ThemeProvider theme={headerTheme}>
			<div data-header-animate className={styles.header}>
				<div className={`container ${styles.containerFull}`}>
					<Link to={Routes.COMMON_PROJECTS}>
						<img src={'/assets/images/svg/new-logo.svg'} alt='LOGO' />
					</Link>

					{role && role === 999 && (
						<>
							<div className={styles.demoSign}>
								<img src={'/assets/images/svg/attention.svg'} alt='Демо' />
								<span>Ограниченный демо-доступ</span>
							</div>
						</>
					)}

					<div className={styles.container}>
						<HeaderNav />
						<HeaderAvatar logout={() => dispatch(logout())} />
					</div>
				</div>
				{/* <HeaderSearch /> */}
			</div>
		</ThemeProvider>
	)
}

export default HeaderContainer
