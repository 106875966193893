import { PublicType, PublicTypes } from '../actions'
import { IPublic } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

const initialState: IPublic = {
	policesGipro: false,
	policesRGCO: false,
	groups: [],
	company: [],
	regions: [],
	uncMains: [],
	publicationDates: [],
	purchasePublishDates: [],
	yearBegin: '',
	yearEndOffer: '',
	uncSections: [],
	uncThs: [],
	uncThDescriptions: [],
	rgStatuses: [],
	purchasesMethods: [],
	purchasesStages: [],
	purchasesOkpd: [],
	purchasesOkved: [],
	countPublicInfoFetches: 0,
	uncTechnicalSpecifications: {
		name: {},
		paramName: {},
		param: {},
	},
}
const publicFn = (draft: Draft<IPublic>, action: PublicTypes) => {
	switch (action.type) {
		case PublicType.SET_PUBLIC_INFO:
			draft[action.data.infoName] = action.data.infoData
			break
		case PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS:
			draft.uncTechnicalSpecifications = action.uncTechnicalSpecifications
			break
		case PublicType.SET_PURCHASES_METHODS:
			draft.purchasesMethods = action.purchasesMethods
			break
		case PublicType.SET_PURCHASES_STAGES:
			draft.purchasesStages = action.purchasesStages
			break
		case PublicType.SET_PURCHASES_OKPD:
			draft.purchasesOkpd = action.purchasesOkpd
			break
		case PublicType.SET_PURCHASES_OKVED:
			draft.purchasesOkved = action.purchasesOkved
			break
		case PublicType.COUNT_PUBLIC_INFO_FETCHES:
			draft.countPublicInfoFetches = action.countPublicInfoFetches ? draft.countPublicInfoFetches + 1 : 0
			break
		default:
			return draft
	}
}
export const publicReducer = produce(publicFn, initialState)
