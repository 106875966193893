import React, { useEffect, useState } from 'react'

import { isEqual } from 'lodash'
import { Select } from '@root-gipro/shared/FormElements'
import FilterItemCheckbox from './FilterItemCheckbox'
import { Box, Button, fade, makeStyles, TextField, IconButton, Divider, CircularProgress } from '@material-ui/core'
import { FilterSearchCheckboxesProps, ICheckboxItem } from '../models/filter-item-search-checkboxes.props'
import FilterItemCheckboxSearch from './FilterItemCheckboxSearch'

const useStylesBtn = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
		fontWeight: 400,

		'&:hover': {
			backgroundColor: 'transparent',
			fontWeight: 500,
			// textDecoration: 'underline',
		},
	},
}))

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		height: 'auto',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			borderColor: theme.palette.primary.main,
		},
		'& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
			paddingTop: 7,
			paddingBottom: 6,
		},
		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
	focused: {},
}))

const FilterItemSearchCheckbox: React.FC<FilterSearchCheckboxesProps> = ({
	value,
	setValue,
	name,
	placeholder,
	isDisable,
	fieldName,
	items,
	withCheckbox,
	isMultiple,
	convertValue,
	disableCloseOnSelect,
}) => {
	const [checkedItems, setCheckboxItems] = useState(value || [])
	const [allCheckboxesItems, setAllCheckboxesItems] = useState<ICheckboxItem[]>([])
	const [query, setQuery] = useState('')
	const [notFilteredItems, setNotFilteredItems] = useState(false)
	const [resetCheckboxes, setResetCheckboxes] = useState(false)

	const classes2 = useStylesBtn()
	const classes = useStylesReddit()

	useEffect(() => {
		// начальное значение
		setAllCheckboxesItems(items)
	}, [items.length])

	useEffect(() => {
		if (!isEqual(value, checkedItems)) {
			setCheckboxItems(value || [])
		}
		// при сбросе всех фильтров
		if (value == undefined) {
			setCheckboxItems([])
			setResetCheckboxes(prev => !prev)
			setQuery('')
		}
	}, [value])

	useEffect(() => {
		setValue(fieldName, checkedItems)
	}, [checkedItems])

	const resetCheckedItems = () => {
		setCheckboxItems([])
		setResetCheckboxes(false)
		setQuery('')
	}

	const updateData = (value: string) => {
		if (checkedItems.indexOf(value) == -1) {
			setResetCheckboxes(false)
			setCheckboxItems([...checkedItems, value])
		} else {
			setCheckboxItems((prev: any[]) => prev.filter(prevItem => prevItem !== value))
		}
	}

	return (
		<div style={{ position: 'relative' }}>
			{fieldName.split('.')[0] === 'uncCell' ? <h4 style={{ marginTop: '16px' }}>{placeholder}</h4> : null}
			{checkedItems.length ? (
				<Button
					type='reset'
					variant='text'
					size='small'
					color='primary'
					classes={classes2}
					className='aside-filter__reset link link-blue'
					style={{ textTransform: 'none', padding: 0 }}
					disableFocusRipple
					disableRipple
					onClick={resetCheckedItems}
				>
					Очистить
				</Button>
			) : null}
			<TextField
				value={query}
				placeholder='Поиск...'
				margin='dense'
				hiddenLabel
				variant='filled'
				InputProps={{
					classes,
					disableUnderline: true,
					endAdornment: query && (
						<>
							<IconButton type='button' onClick={() => setQuery('')} className='reset-icon'>
								<span className='close-icon' />
							</IconButton>
						</>
					),
				}}
				color='primary'
				onChange={e => setQuery(e.target.value)}
				fullWidth
			/>

			<div style={{ maxHeight: '230px', paddingBottom: '8px', overflowY: 'scroll', overflowX: 'hidden' }}>
				{allCheckboxesItems
					.filter((el: ICheckboxItem) => {
						if (query === '') {
							return el
						} else if (el.name.toLowerCase().trim().includes(query.toLowerCase().trim())) {
							return el
						} else {
						}
					})
					.map((item: ICheckboxItem) => (
						<FilterItemCheckboxSearch
							key={item.id}
							value={false}
							name={item.name}
							projectsCount={item.projectsCount}
							fieldName={fieldName}
							isDisable={isDisable}
							setValue={() => console.log('r')}
							updateData={updateData}
							isChecked={checkedItems}
						/>
					))}
			</div>

			{allCheckboxesItems.length ? null : (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px 0' }}>
					<CircularProgress size={24} style={{ color: 'primary' }} />
				</div>
			)}
			{/* {allCheckboxesItems.length ? <Divider className='aside-filter__divider' /> : null} */}
		</div>
	)
}

export default FilterItemSearchCheckbox
