import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import uniqueId from 'lodash/uniqueId'
import { CardRightSidePrices } from '@root-gipro/modules/purchases/models/consts/card-right-side-prices.const'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import { CardNoticeContentItem } from '@root-gipro/modules/purchases/models/consts/card-inside-content-items.const'
import { CardFull, CardLeft, CardRight, CardItem } from '@root-gipro/shared/Card'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { LingSVG } from '@root-gipro/shared/SVGComponents/LinkSVG'
import PurchasesCollapse from '../../PurchasesCollapse/PurchasesCollapse'
import { PurchaseCollapseProps } from '../../PurchasesCollapse/purchase-collapse.props'
import { formatDate } from '@root-gipro/utils/helpers/common'
import { ICommonProject } from '@root-gipro/store/interfaces'

const DetailPlanPurchase: React.FC<PurchaseCollapseProps> = ({ purchase, toggleInvestDetail, children }) => {
	const { company, regions } = useSelector((state: IState) => state.public)
	const [isExpand, setExpand] = useState(false)
	const detailPurchase = {
		purchase,
		toggleInvestDetail,
	}

	const linkedProjects: ICommonProject[] = purchase.linkedProjects
	// console.log(purchase.linkedProjects)

	return (
		<>
			<CardFull classes='detail-purchases'>
				{purchase.plan &&
					purchase.plan.map((noticePurchase: IPurchase) => (
						<div className='card-inside__head'>
							<div className={`card-inside__detailed-expand-info__item item-lg`}>
								<div className={`card-inside__detailed-expand-value `}>{purchase.subject}</div>
							</div>
							{CardNoticeContentItem.map(item => (
								<div className={`card-inside__detailed-expand-info__item item-sm`}>
									<div className='card-inside__detailed-expand-title'>{item.title}</div>
									<div
										className={`card-inside__detailed-expand-value ${
											item.title === null && 'card-container__detailed--disabled'
										}`}
										onClick={item.click}
									>
										<span>{item.value(purchase) ? item.value(purchase) : 'Нет данных'}</span>
									</div>
								</div>
							))}
						</div>
					))}

				<div className='card-full__item' key={purchase.id}>
					<div className='card-full-wr'>
						<CardLeft>
							<div className='card-full__header'>
								<div className='card-full__code link-blue'>
									<span className='purchase-icon' />
									<span className={'code'}>{purchase.registration_number}</span>
								</div>
								<div className='card-full__actions'>
									<span
										className='card-full_detailed-btn'
										onClick={() => {
											setExpand(!isExpand)
										}}
									>
										{isExpand ? 'Свернуть' : 'Подробнее'}
									</span>

									<div className='icon-wrapper' onClick={() => window.open(purchase.url)}>
										<span className='linkIcon'>
											<LingSVG className='link-icon' />
										</span>
									</div>
								</div>
							</div>
							<CardDate>Изменено {formatDate(purchase.last_modified_date)}</CardDate>
							<CardTitle>{company.find(company => company.id === purchase.company_id)?.name || ''}</CardTitle>
							<CardDescription>{purchase.subject}</CardDescription>
							<div className='purchase_status'>{purchase.status ? purchase.status : '-'}</div>

							<div>
							{linkedProjects.length > 0 && <span className="linkedProjectListTitle">Инвестиционные проекты</span>}

							{linkedProjects.map(project => (
								<li className='linkedProjectList'>
									<span className='linkIcon' onClick={() => window.open('/common-projects/project/'+project.id+'/parameters')}>{project.code}</span>
									{project.name}
								</li>
							))}

							</div>

						</CardLeft>

						<CardRight>
							{CardRightSidePrices.map(price => (
								<CardItem title={price.title} value={price.value(purchase)} key={uniqueId('CardItem')} />
							))}
						</CardRight>
					</div>
					<PurchasesCollapse {...detailPurchase} isExpand={isExpand} onList={false} />
				</div>
			</CardFull>
			{children}
		</>
	)
}

export default DetailPlanPurchase
