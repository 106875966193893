import React, { useEffect } from 'react'
import '@root-gipro/modules/investmentPrograms/styles/InvestmentPrograms.scss'
import InvestmentProgramsList from '@root-gipro/modules/investmentPrograms/components/sections/List'
import InvestmentProgramsFilter from '@root-gipro/modules/investmentPrograms/components/sections/Filter'
import InvestmentProgramsHeader from '@root-gipro/modules/investmentPrograms/components/sections/Header'
import { useDispatch } from 'react-redux'
import { getInvestmentPrograms } from '@root-gipro/modules/investmentPrograms/store/actions'
import ym from 'react-yandex-metrika'

const Main = () => {
    const dispatch = useDispatch()
    dispatch(getInvestmentPrograms())

    useEffect(() => {
        ym('hit', '/ipr')
    }, [])

    return (
        <div className="programs">
            <InvestmentProgramsFilter />
            <div className="programs__main">
                <InvestmentProgramsHeader />
                <InvestmentProgramsList />
            </div>
        </div>
    )
}

export default React.memo(Main)
