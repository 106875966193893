import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Chip, Tooltip } from '@material-ui/core'
import { IState } from '@root-gipro/store/interfaces'
import {
	filtersRequest,
	refreshFilterState,
	setCallbacks,
	setFilterSortParams,
} from '@root-gipro/modules/filter/store/actions'
import { showFilterResult as showFilterResultHandler } from '@root-gipro/modules/filter/store/actions'
import {
	getCommonProjects,
	loadingCommonProjects,
	setCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { commonProjectsApi } from '@root-gipro/store/api'

const FilterCloud = () => {
	const location = useLocation()
	const searchUrl: string = location.search
	const url: string = location.pathname.slice(1)
	const dispatch = useDispatch()

	let { rgStatuses, minMax } = useSelector((state: IState) => state.public)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	const convertPlug = (fieldName: string, value: any) => (
		fieldName: string,
		value: any
	): { fieldName: string; value: any } => ({ fieldName, value })

	const { getInitList, showFilterResult } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)

	const [localResultFilterState, setLocalResultFilterState] = useState<{ [key: string]: string | string[] | number }>(
		{}
	)

	const params = {
		limit: 10,
		with: [
			'resultsCnt',
			'valuationOfferForecastSum',
			'valuationPlanForecastSum',
			'projects',
			'uncInfo',
			'uncTechnicalSpecifications',
		],
	}
	const [refreshCloud, setRefreshCloud] = useState(false)

	useEffect(() => {
		setLocalResultFilterState(resultFilterState)
	}, [searchUrl])

	const deleteChip = (fieldName: string, value: any) => {
		setRefreshCloud(!refreshCloud)
		dispatch(refreshFilterState(fieldName, value, url, convertPlug(fieldName, value)))
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))
		// @ts-ignore
		setLocalResultFilterState(prev => prev[fieldName])
	}

	const deleteChipWithArr = (fieldName: string, currValue: string) => {
		setRefreshCloud(!refreshCloud)
		// @ts-ignore
		const newVal = resultFilterState[fieldName].filter(el => {
			return el !== currValue
		})
		dispatch(refreshFilterState(fieldName, newVal, url, convertPlug(fieldName, newVal)))
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))
	}

	const deleteChipRange = (fieldNameMin: any, fieldNameMax: any) => {
		setRefreshCloud(!refreshCloud)
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMin, null, url, convertPlug(fieldNameMin, null)))
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMax, null, url, convertPlug(fieldNameMax, null)))

		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))
	}

	return (
		<>
			{localResultFilterState.code ? (
				<Tooltip title='Идентификатор'>
					<Chip onDelete={() => deleteChip('code', '')} label={localResultFilterState.code} />
				</Tooltip>
			) : null}
			{localResultFilterState.name ? (
				<Tooltip title='Наименование'>
					<Chip onDelete={() => deleteChip('name', '')} label={localResultFilterState.name} />
				</Tooltip>
			) : null}
			{localResultFilterState.existsPIR ? (
				<Chip onDelete={() => deleteChip('existsPIR', false)} label={'Проектно-изыскательские работы'} />
			) : null}
			{localResultFilterState.existsUNC ? (
				<Chip onDelete={() => deleteChip('existsUNC', false)} label={'С подробными техническими характеристиками'} />
			) : null}
			{localResultFilterState.groupId
				? //@ts-ignore
				  localResultFilterState.groupId.map((item: string) => (
						<Tooltip title='Организация' key={item}>
							<Chip onDelete={() => deleteChipWithArr('groupId', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.regionId
				? //@ts-ignore
				  localResultFilterState.regionId.map((item: string) => (
						<Tooltip title='Регион' key={item}>
							<Chip onDelete={() => deleteChipWithArr('regionId', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{
				//@ts-ignore
				localResultFilterState['yearBegin.min'] && localResultFilterState['yearEndOffer.max'] ? (
					//@ts-ignore
					<Tooltip title='Период'>
						{
							//@ts-ignore
							(minMax && localResultFilterState['yearBegin.min'] !== `${minMax.yearBegin.min}-01-01`) ||
							//@ts-ignore
							(minMax && localResultFilterState['yearEndOffer.max'] !== `${minMax.yearEndOffer.max}-12-31`) ? (
								<Chip
									onDelete={() => deleteChipRange('yearBegin.min', 'yearEndOffer.max')}
									label={localResultFilterState['yearBegin.min'] + ' - ' + localResultFilterState['yearEndOffer.max']}
								/>
							) : (
								<div></div>
							)
						}
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				localResultFilterState['estimatedOfferYM.min'] && localResultFilterState['estimatedOfferYM.max'] ? (
					//@ts-ignore
					<Tooltip title='Дата добавления'>
						{
							//@ts-ignore
							(minMax &&
								localResultFilterState['estimatedOfferYM.min'] !==
									`${minMax.estimatedOfferYM.min.split('-')[0]}-01-01`) ||
							(minMax &&
								localResultFilterState['estimatedOfferYM.max'] !==
									`${minMax.estimatedOfferYM.max.split('-')[0]}-12-31`) ? (
								<Chip
									onDelete={() => deleteChipRange('estimatedOfferYM.min', 'estimatedOfferYM.max')}
									label={
										localResultFilterState['estimatedOfferYM.min'] +
										' - ' +
										localResultFilterState['estimatedOfferYM.max']
									}
								/>
							) : (
								<div></div>
							)
						}
					</Tooltip>
				) : null
			}
			{/* в зависимости от радиокнопок */}
			{
				//@ts-ignore
				localResultFilterState['estimatedOfferCurrent.min'] && localResultFilterState['estimatedOfferCurrent.max'] ? (
					//@ts-ignore
					<Tooltip title='Полная сметная стоимость согласно ПСД (с НДС)'>
						<Chip
							label={
								localResultFilterState['estimatedOfferCurrent.min'] +
								' - ' +
								localResultFilterState['estimatedOfferCurrent.max']
							}
						/>
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				localResultFilterState['valuationUncOfferForecast.min'] &&
				localResultFilterState['valuationUncOfferForecast.max'] ? (
					//@ts-ignore
					<Tooltip title='Оценка полной стоимости по УНЦ'>
						<Chip
							label={
								localResultFilterState['valuationUncOfferForecast.min'] +
								' - ' +
								localResultFilterState['valuationUncOfferForecast.max']
							}
						/>
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				localResultFilterState['balanceOffer.min'] && localResultFilterState['balanceOffer.max'] ? (
					//@ts-ignore
					<Tooltip title='Оценка в прогнозных ценах'>
						<Chip
							onDelete={e => alert(e)}
							label={localResultFilterState['balanceOffer.min'] + ' - ' + localResultFilterState['balanceOffer.max']}
						/>
					</Tooltip>
				) : null
			}
			{/* в зависимости от радиокнопок */}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.name']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.name'].map((item: string) => (
							<Tooltip title='Строительные ресурсы (Наименование)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.name', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.paramName']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.paramName'].map(
							(item: string) =>
								//@ts-ignore
								// <Tooltip title='Строительные ресурсы (Параметр)' key={Math.random()}>
								//@ts-ignore
								item !== '-' && (
									<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.paramName', item)} label={item} />
								)
							// </Tooltip>
					  )
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.param']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.param'].map(
							(item: string) =>
								//@ts-ignore
								// <Tooltip title='Строительные ресурсы (Значение)' key={item}>
								//@ts-ignore
								item !== '-' && (
									<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.param', item)} label={item} />
								)
							// </Tooltip>
					  )
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['unc.count.min'] && localResultFilterState['unc.count.max'] ? (
					//@ts-ignore
					<Tooltip title='Стоимость'>
						<Chip
							onDelete={e => alert(e)}
							label={localResultFilterState['unc.count.min'] + ' - ' + localResultFilterState['unc.count.max']}
						/>
					</Tooltip>
				) : null
			}
			{/* Технические решения */}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncThId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncThId'].map((item: string) => (
							//@ts-ignore
							<Tooltip title='Технические решения (Наименование)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncThId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncTextParameterId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncTextParameterId'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Параметры)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncTextParameterId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.voltageValue']
					? //@ts-ignore
					  localResultFilterState['uncCell.voltageValue'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Напряжение)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.voltageValue', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncMainId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncMainId'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Таблица)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncMainId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.number']
					? //@ts-ignore
					  localResultFilterState['uncCell.number'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Строка)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.number', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.subNumber']
					? //@ts-ignore
					  localResultFilterState['uncCell.subNumber'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Столбец)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.subNumber', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
		</>
	)
}

export default FilterCloud
