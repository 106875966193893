import { call, takeEvery, put, takeLatest, debounce } from 'redux-saga/effects'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import {
	setFilterResultCount,
	filtersRequest,
	isLoadingCountResult,
	isShowFilterCloud,
} from '@root-gipro/modules/filter/store/actions'
import store from '@root-gipro/store'
import {
	setCommonProjectsCount,
	setCommonProjectsSum,
	setUncInfo,
	setAllowMoreProjects,
	setCommonProjectsPaginationCount,
	setFilterIdsCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { ICommonProject, IUncInfo } from '@root-gipro/store/interfaces'

import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { setPublicUncTechnicalSpecifications } from '@root-gipro/store/actions/public'
import { setFilterIdsPurchases } from '@root-gipro/modules/purchases/store/actions'
import {
	setFavoritesProjectsCount,
	setFavoritesProjectsSum,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
} from '@root-gipro/modules/favorites/store/actions'
import { setUserProjects } from '../../userProjects/store/actions'

function* refreshFilterCountResult(action: any) {
	const state = store.getState().filter

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: action.url === 'user-projects'
			? state.userProjects
			: state.userProjects

	const object = excludeData(currentFilter.showFilterState)
	const params = {
		...object,
		...currentFilter.sortState,
	}

	if (state.showFilterResult) {
		const res = yield call(state.showFilterResult, params)
		// надо решить вопрос с обработкой запроса при одновременном указании параметров строительные ресурсы и технические решения! Пока такая заплатка
		if (res) {
			yield put(setPublicUncTechnicalSpecifications(res.uncTechnicalSpecifications)) // для обновления массива uncTechnicalSpecifications при сбросе фильтра

			yield put(setFilterResultCount(res.resultsCnt, action.url))
			yield put(isLoadingCountResult(false))

			if (action.url === 'user-projects') {
				yield put(setFilterResultCount(res.userProjectsCnt, action.url))
				yield put(isLoadingCountResult(false))
			}
		}
	}
}

function* showFilterResult(action: any) {
	const state = store.getState().filter
	const { sort } = store.getState().commonProjects
	const { sort: sortUserProjectsList } = store.getState().userProjects
	const { favoritesSort } = store.getState().favorites
	const { sortParams, paginationCounter } = store.getState().purchases

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: action.url === 'user-projects'
			? state.userProjects
			: state.userProjects

	const object = excludeData(currentFilter.showFilterState)
	const width = (currentFilter && currentFilter.sortState?.with) || []

	let params = {}
	if (action.url === 'purchases') {
		params = {
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	}
	if (action.url === 'favorites-purchases') {
		params = {
			// @ts-ignore
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			favoritesOnly: 1,
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	}
	if (action.url === 'common-projects') {
		params = {
			...object,
			limit: 10,
			with: [...width, 'IDs'],
			order: (sort.order ? '' : '-') + sort.sortName,
			...params,
		}
	}
	if (action.url === 'favorites-common-projects') {
		params = {
			// @ts-ignore
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			favoritesOnly: 1,
			order: (favoritesSort.order ? '' : '-') + favoritesSort.sortName,
			...params,
		}
	}

	if (action.url === 'user-projects') {
		params = { order: sortUserProjectsList.order, ...object }
	}

	if (state.showFilterResult && state.refreshList && state.toggleLoadingList) {
		yield put(state.toggleLoadingList(true))

		const res = yield call(state.showFilterResult, params)
		// console.log(res)
		if (res?.projects) {
			const projects: ICommonProject[] = res.projects
			const count: number = Number(res.resultsCnt)
			const valuationOfferForecastSum: number = Number(res.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(res.valuationPlanForecastSum) / 10 ** 6
			const uncInfo: IUncInfo = res.uncInfo
			const IDs: string[] = res.IDs
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (uncInfo.voltageValues) {
				const newVoltageValues = Object.values(uncInfo.voltageValues)
				uncInfo.voltageValues = newVoltageValues
			} else {
				uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}
			if (action.url === 'favorites-common-projects') {
				yield put(setFavoritesProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(setFavoritesProjectsCount(count))
				yield put(state.refreshList(projects, false))
				yield put(state.toggleLoadingList(false))
			} else {
				yield put(setCommonProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(state.refreshList(projects, false))
				yield put(setAllowMoreProjects(true))
				yield put(setCommonProjectsCount(count))
				yield put(setCommonProjectsPaginationCount(0))
				yield put(setUncInfo(uncInfo))
				yield put(state.toggleLoadingList(false))
				yield put(setFilterIdsCommonProjects(IDs))
			}
		} else if (res?.purchases) {
			const count: number = Number(res.resultsCnt)
			const purchases: ICommonProject[] = res.purchases
			const maximumPriceSum = Number(res.maximumPriceSum) / 10 ** 6
			if (action.url === 'favorites-purchases') {
				yield put(state.refreshList(purchases, false))
				yield put(setFavoritesPurchasesSum(maximumPriceSum))
				yield put(setFavoritesPurchasesCount(count))
			} else {
				yield put(state.refreshList(res, false))
				yield put(setFilterIdsPurchases(res.IDs))
			}
		} else if (res?.userProjects) {
			yield put(setUserProjects(res.userProjects, res.totalInvestmentUncOfferSum, res.userProjectsCnt))
		} else {
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (res.uncInfo.voltageValues) {
				res.uncInfo.voltageValues = Object.values(res.uncInfo.voltageValues)
			} else {
				res.uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}
			yield put(state.refreshList(res, false))
			yield put(setFilterIdsPurchases(res.IDs))
		}
	}
}

function* handleChangeFilterSaga(action: any) {
	yield put(filtersRequest(action.url))
}

export default function* watchFilter() {
	yield takeEvery(FilterActions.SHOW_FILTER_RESULT, showFilterResult)
	yield debounce(500, FilterActions.REFRESH_FILTER_STATE, handleChangeFilterSaga)
	yield takeLatest(FilterActions.FILTER_REQUEST, refreshFilterCountResult)
}
