import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import {
	Checkbox,
	InputAdornment,
	ListItemText,
	TextField,
	Slider,
	Box,
	makeStyles,
	fade,
	Tooltip,
} from '@material-ui/core'
import { floatFormat, spaceFormat } from '@root-gipro/utils/helpers/helperInputFloat'
import { isEqual } from 'lodash'
import { FilterItemRangePriceProps } from '@root-gipro/shared/Filter/models/filter-item-range-price.props'
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FilterItemDateRangeProps } from '@root-gipro/shared/Filter/models/filter-item-date-range.props'

import DateFnsUtils from '@date-io/date-fns'

import '@root-gipro/modules/commonProjects/styles/DurationDate.scss'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import TooltipHelp from '@root-gipro/shared/Tooltips/TooltipHelp'

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		padding: 0,
		height: 'auto',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'& .MuiFilledInput-inputHiddenLabel': {
			paddingTop: 4,
			paddingBottom: 4,
		},

		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
			borderColor: theme.palette.primary.main,
		},
	},
	focused: {},
}))

const FilterItemDateRange: React.FC<FilterItemDateRangeProps> = ({
	name,
	startName = 'с',
	endName = 'по',
	withCheckbox,
	minMax = { min: 2018, max: 2055 },
	setEnd,
	setStart,
	end,
	start,
	fieldNameStart,
	fieldNameEnd,
	disabledAll,
	format = 'MM.yyyy',
	openTo = 'date',
	views = ['year', 'date', 'month'],
}) => {
	const classes = useStylesReddit()
	let minValueRange = minMax.min
	let maxValueRange = minMax.max

	if (fieldNameStart === 'yearBegin.min' && fieldNameEnd === 'yearEndOffer.max') {
		minValueRange = Number(minMax[fieldNameStart.replace('.min', '')].min)
		maxValueRange = Number(minMax[fieldNameEnd.replace('.max', '')].max)
	} else if (fieldNameStart === 'estimatedOfferYM.min' && fieldNameEnd === 'estimatedOfferYM.max') {
		minValueRange = Number(minMax[fieldNameStart.replace('.min', '')]?.min.split('-')[0])

		maxValueRange =
			Number(minMax[fieldNameEnd.replace('.max', '')].max.split('-')[0]) > 8000
				? 2055
				: Number(minMax[fieldNameEnd.replace('.max', '')].max.split('-')[0])
	}
	useEffect(() => {
		if (!end && !start) {
			let dateStart = new Date(`${minValueRange}-01-01`)
			let dateEnd = new Date(`${maxValueRange}-12-31`)
			setStart(dateStart)
			setEnd(dateEnd)
			setValue([minValueRange, maxValueRange])
		}
		if (!isEqual(start, startDate) && start! > end!) {
			setStart(end || null)
		}
		if (!isEqual(end, endDate) && end! < start!) {
			setEnd(start || null)
		}
		if (!isEqual(start, startDate)) {
			setStartDate(start || null)
		}
		if (!isEqual(end, endDate)) {
			setEndDate(end || null)
		}
	}, [start, end])

	const [startDate, setStartDate] = useState(start || null)
	const [endDate, setEndDate] = useState(end || null)

	const resetFields = () => {
		setStart(null)
		setEnd(null)
		setValue([minValueRange, maxValueRange])
	}

	const [value, setValue] = React.useState<number[]>([minValueRange, maxValueRange])

	const handleStartDateChange = (date: Date | null) => {
		setValue([date?.getFullYear(), maxValueRange])
		setStartDate(date)
	}
	const handleEndDateChange = (date: Date | null) => {
		setValue([maxValueRange, date?.getFullYear()])
		setEndDate(date)
	}

	const handleChange = (event: any, newValue: any) => {
		let dateStart = new Date(`${newValue[0]}-01-01`)
		let dateEnd = new Date(`${newValue[1]}-12-31`)
		handleStartDateChange(dateStart)
		handleEndDateChange(dateEnd)
		setValue(newValue as number[])
	}
	const handleChangeCommitted = (event: any, newValue: any) => {
		let dateStart = new Date(`${newValue[0]}-01-01`)
		let dateEnd = new Date(`${newValue[1]}-12-31`)
		setStart(dateStart)
		setEnd(dateEnd)
	}

	return (
		<div className='duration-date'>
			<div className='duration-date__head'>
				<h5 className='duration-date__head-title'>{name}</h5>
				{fieldNameStart === 'estimatedOfferYM.min' ? (
					<div style={{ marginLeft: '6px', marginBottom: '-2px' }}>
						<TooltipHelp title='Год выполнения расчета. Расчет стоимости проекта в текущем уровне цен зависит от индекс-дефляторов при оценке полной стоимости проекта по УНЦ, от квартальных коэффициентов при расчете стоимости по ПСД'></TooltipHelp>
					</div>
				) : null}
				<span className='link link-grey' style={{ marginLeft: 'auto' }} onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<div className='duration-date__pickers'>
					<DatePicker
						disableToolbar
						variant='inline'
						format={format}
						margin='normal'
						id='date-picker-inline'
						value={startDate}
						inputVariant='filled'
						hiddenLabel
						emptyLabel=''
						autoOk
						InputProps={{
							classes,
							disableUnderline: true,
						}}
						invalidLabel='Введите год'
						onChange={handleStartDateChange}
					/>
					<div>—</div>
					<DatePicker
						disableToolbar
						variant='inline'
						format={format}
						margin='normal'
						id='date-picker-inline'
						value={endDate}
						inputVariant='filled'
						hiddenLabel
						emptyLabel=''
						autoOk
						InputProps={{
							classes,
							disableUnderline: true,
						}}
						maxDate={Date.parse(`${maxValueRange + 1}-01-01`)}
						maxDateMessage={`Максимальный возможный год - ${maxValueRange}`}
						invalidLabel='Введите год'
						onChange={handleEndDateChange}
					/>
				</div>
			</MuiPickersUtilsProvider>
			<Slider
				value={value}
				onChange={handleChange}
				onChangeCommitted={handleChangeCommitted}
				min={minValueRange}
				max={maxValueRange}
			/>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox
						disabled={withCheckbox.disabled}
						checked={withCheckbox.checked}
						onChange={() => {
							if (!withCheckbox) {
								return
							}
							if (withCheckbox.checkboxChange) withCheckbox.checkboxChange()
							withCheckbox.setChecked(!withCheckbox.checked)
							// if (!withCheckbox.checked) resetFields() // приводит к сбрасыванию дынных
						}}
					/>
					<ListItemText primary={withCheckbox.label} />
				</div>
			)}
		</div>
	)
}

export default FilterItemDateRange
