import React, { useEffect } from 'react'
import '@root-gipro/modules/developmentSchemesAndPrograms/styles/DevelopmentSchemesAndPrograms.scss'
import DevelopmentSchemesAndProgramsList from '@root-gipro/modules/developmentSchemesAndPrograms/components/sections/List'
import DevelopmentSchemesAndProgramsFilter from '@root-gipro/modules/developmentSchemesAndPrograms/components/sections/Filter'
import DevelopmentSchemesAndProgramsHeader from '@root-gipro/modules/developmentSchemesAndPrograms/components/sections/Header'
import { useDispatch } from 'react-redux'
import { getDevelopmentSchemesAndPrograms } from '@root-gipro/modules/developmentSchemesAndPrograms/store/actions'
import ym from 'react-yandex-metrika'

const Main = () => {
    const dispatch = useDispatch()
    dispatch(getDevelopmentSchemesAndPrograms())

    useEffect(() => {
        ym('hit', '/sipr')
    }, [])

    return (
        <div className="programs">
            <DevelopmentSchemesAndProgramsFilter />
            <div className="programs__main">
                <DevelopmentSchemesAndProgramsHeader />
                <DevelopmentSchemesAndProgramsList />
            </div>
        </div>
    )
}

export default React.memo(Main)
