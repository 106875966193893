import { CardRightSidePrices } from '@root-gipro/modules/purchases/models/consts/card-right-side-prices.const'
import { PurchaseCollapseDetail } from '@root-gipro/modules/purchases/models/consts/purchase-collapse-detail.const'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import { Routes } from '@root-gipro/router'
import {
	Card,
	CardCollapse,
	CardDetailedInfo,
	CardDetailedInfoItem,
	CardHeaderTitle,
	CardItem,
	CardLeft,
	CardRight,
} from '@root-gipro/shared/Card'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { IGroup, IRegion, ICompany } from '@root-gipro/store/interfaces'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { formatDate, hasUndefinedKeys } from '@root-gipro/utils/helpers/common'
import { uniqueId } from 'lodash'
import React, { FC, memo, ReactText, useCallback, useMemo, useState, useEffect } from 'react'
import { cardRightItems } from '../models/consts/card-right-items.const'
import { collapseItemsLeftColumn } from '../models/consts/collapse-items-left-column.const'
import { collapseItemsRightColumn } from '../models/consts/collapse-items-right-column.const'
import { ProjectType } from '../models/enums/projects.enum'
import {
	CardButtonPurchase,
	CardLeftStyle,
	CardRightStyle,
	Icon,
	IconArrowStyle,
	TopBlockPurchase,
	WrapperCardStyle,
	WrapperInfoBlock,
	WrapperPurchase,
	WrapperStage,
} from '@root-gipro/modules/commonProjects/components/CardProject/styles'
import { fetchData } from '@root-gipro/store/api'
import { LingSVG } from '@root-gipro/shared/SVGComponents/LinkSVG'
import { StarSVG } from '@root-gipro/shared/SVGComponents/StarSVG'
import { Link, useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { getPurchaseDetailRoute } from '@root-gipro/modules/purchases/utils/getPurchaseDetailRoute'
import { actionFavoriteCommonProjectsAndChangePurchases } from '@root-gipro/modules/commonProjects/store/actions'
import { useDispatch } from 'react-redux'
import { DateFromTimestamp } from '@root-gipro/shared/Date/DateFromTimestamp'

type Purchase = {
	id: number
	subject: string
	price?: number
	publish_date: string | Date
	registration_number: number
	url: string
	isFavorite: boolean
	isNew: boolean
	request_receiving_end_date?: string
}

type Stage = {
	stage: string
	created_at: string
}

interface Props {
	classIcon: string
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES
	project: any
	groups: IGroup[]
	company: ICompany[]
	region: string
	regions: IRegion[]
	handlerFavoritesActions(projectId: string, action: 'DELETE' | 'POST', project: any): void
	handlerLinkClick?(value: string): void
}

const Item: FC<Props> = memo(
	({
		regions,
		region,
		groups,
		company,
		toggleType,
		classIcon,
		project,
		handlerFavoritesActions,
		handlerLinkClick,
	}) => {
		const [details, setDetails] = useState<boolean>(false)
		const routePath =
			toggleType === ProjectType.PROJECTS
				? Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, project.id)
				: Routes.PURCHASES_DETAIL.replace(/:id/g, project.id.toString()).replace(/:type/g, 'plan')

		const date =
			toggleType === ProjectType.PROJECTS
				? `Изменено ${formatDate(project.publicationDate)}`
				: `Изменено ${formatDate(project.publish_date)}`

		const companyName: string | undefined =
			toggleType === ProjectType.PROJECTS
				? groups.find((group: IGroup) => group.id === project.groupId)?.name
				: company.find((company: ICompany) => company.id === project.company_id)?.name

		const checkRight = useCallback(() => {
			if (toggleType === ProjectType.PROJECTS) {
				return cardRightItems(project).map(
					item => item.value && <CardItem title={item.title} value={item.value} key={uniqueId('CardItem_')} />
				)
			} else {
				return CardRightSidePrices.map(
					price =>
						price.value(project) && (
							<CardItem title={price.title} value={price.value(project)} key={uniqueId('CardItem_')} />
						)
				)
			}
		}, [toggleType])

		const detailInfoLeft = useCallback(() => {
			if (toggleType === ProjectType.PROJECTS) {
				return collapseItemsLeftColumn(project, region)
					.filter((item: any) => !hasUndefinedKeys(item) && item.value)
					.map((item: any, i: any) => (
						<CardDetailedInfoItem key={project.id + i} title={item.title} value={item.value} icon={item.icon} />
					))
			} else {
				return PurchaseCollapseDetail(regions).map(item => (
					<CardDetailedInfoItem
						title={item.title}
						value={item.value(project as IPurchase)}
						widthContent={item.widthContent && item.widthContent}
						icon={item.icon}
						key={uniqueId('CardDetailedInfo')}
					/>
				))
			}
		}, [toggleType])

		const itemsRight = useMemo(() => checkRight(), [toggleType])
		const itemsDetailsLeft = useMemo(() => detailInfoLeft(), [toggleType])

		const expandCardHandler = useCallback(
			(id: ReactText) => {
				setDetails(!details)
			},
			[toggleType, details]
		)

		const [listPurchases, setListPurchases] = useState<Purchase[]>([])
		const [listStages, setListStages] = useState<Stage[]>([])
		const [showPurchases, setShowPurchases] = useState<boolean>(false)
		const [showStages, setShowStages] = useState<boolean>(false)
		const [showAlert, setShowAlert] = useState(false)
		const history = useHistory()
		const role = Number(window.localStorage.getItem('roleId'))

		useEffect(() => {
			if (toggleType === 'PROJECTS') {
				fetchData(`/projects/${project.id}/linkedPurchases`, (res: unknown) => res)
					.then(purcheses => {
						if (purcheses?.plans) {
							setListPurchases(prev => {
								return [...prev, ...purcheses.plans]
							})
						}
					})
					.catch(error => console.warn(error.message))
			}
		}, [])

		const setFavoriteStatus = (id: number) => {
			let newListPurchases = listPurchases.map(purchases => {
				if (purchases.id === id) {
					purchases.isFavorite = !purchases.isFavorite
				}
				return purchases
			})
			setListPurchases(newListPurchases)
		}

		const dispatch = useDispatch()
		const handlerFavoritesProjectsActions = useCallback(
			(projectId: string, action: 'DELETE' | 'POST', project: any) => {
				dispatch(actionFavoriteCommonProjectsAndChangePurchases(projectId, action, project))
			},
			[]
		)

		return (
			<Card projectId={project.id} key={project.id}>
				<CardLeft>
					<CardHeaderTitle
						path={routePath}
						toggleType={toggleType}
						commonProjectId={toggleType === ProjectType.PROJECTS && project.id}
						purchaseId={toggleType === ProjectType.PURCHASES && project.id}
						title={toggleType === ProjectType.PROJECTS ? project.code : project.registration_number}
						isFavorite={!!+project.isFavorite}
						handlerFavoritesActions={handlerFavoritesActions}
						iconType={classIcon}
						isExpanded={details}
						url_public={project.url_public}
						handlerLinkClick={handlerLinkClick}
						expandCardHandler={expandCardHandler}
					>
						<CardDate>{date}</CardDate>
						<CardTitle>{companyName}</CardTitle>

						<CardDescription>{toggleType === ProjectType.PROJECTS ? project.name : project.subject}</CardDescription>

						{listPurchases.length > 0 && (
							<CardButtonPurchase>
								Закупок: {listPurchases.length}
								{listPurchases.length > 0 && (
									<IconArrowStyle showPurchases={showPurchases} onClick={() => setShowPurchases(!showPurchases)} />
								)}
								{listPurchases.filter(e => e.isNew === true).length > 0 && (
									<span style={{ marginLeft: '0.5em' }}>&#127381;</span>
								)}
							</CardButtonPurchase>
						)}
					</CardHeaderTitle>
					{toggleType === ProjectType.PURCHASES && project.stagesHistory && (
						<div
							className={
								Date.now() / 1000 - project.stagesHistory[project.stagesHistory.length - 1].created_at >
								60 * 60 * 24 * 1
									? 'purchase_status'
									: 'purchase_status_changed'
							}
							onClick={() => {
								setListStages(project.stagesHistory)
								setShowStages(!showStages)
							}}
						>
							{project.stage ? project.stage : '-'}
						</div>
					)}
				</CardLeft>
				<CardRight>{itemsRight.map(item => item)}</CardRight>

				<WrapperPurchase in={showPurchases} unmountOnExit showPurchases={showPurchases}>
					{listPurchases.map((purchase, i) => {
						return (
							<WrapperCardStyle key={nanoid()}>
								<TopBlockPurchase>
									<Icon />
									<Link
										style={{ textDecoration: 'none' }}
										to={{
											pathname: getPurchaseDetailRoute('plan', purchase.id),
											state: {
												from: history.location.pathname,
												fromName: 'Закупки',
											},
										}}
									>
										<span>{purchase.registration_number}</span>
									</Link>
									{
										<div
											onClick={() => {
												// alert('Item')
												// if (purchase.model === 'notice') {
												// 	window.open(
												// 		`https://zakupki.gov.ru/223/purchase/public/purchase/info/common-info.html?regNumber=${purchase.registrationNumber}`
												// 	)
												// } else if (purchase.model === 'plan') {
												// 	let id = purchase.url.split('planInfoId=')[1]
												// 	window.open(
												// 		`https://zakupki.gov.ru/epz/orderplan/purchase-plan/card/position-info.html?id=0&infoId=${id}`
												// 	)
												// }
											}}
										>
											<LingSVG />
										</div>
									}

									{
										<div
											className={`${purchase.isFavorite ? 'icon-wrapper-favorite' : 'icon-wrapper'}`}
											data-popup='costWorks'
											onClick={() => {
												if (role && role === 999) {
													setShowAlert(true)
												} else {
													if (purchase.isFavorite) {
														handlerFavoritesProjectsActions(purchase.id + '', 'DELETE', 'PURCHASES')
														setFavoriteStatus(purchase.id)
													} else {
														handlerFavoritesProjectsActions(purchase.id + '', 'POST', 'PURCHASES')
														setFavoriteStatus(purchase.id)
													}
												}
											}}
										>
											<StarSVG classes='cost-works-icon' chosen={purchase.isFavorite} />
										</div>
									}
									{purchase.isNew && <span>&#127381;</span>}
								</TopBlockPurchase>
								<WrapperInfoBlock>
									<CardLeftStyle>{purchase.subject}</CardLeftStyle>
									<CardRightStyle>
										{purchase?.price && (
											<CardItem
												key={uniqueId('CardTextItem')}
												title='НМЦ контракта (с НДС)'
												value={addHTML(+(purchase.price / 10 ** 6).toFixed(3))}
											/>
										)}
										{purchase?.price && (
											<CardItem
												key={uniqueId('CardTextItem')}
												title='НМЦ контракта (с НДС)'
												value={addHTML(+(purchase.price / 10 ** 6).toFixed(3))}
											/>
										)}
										{purchase.request_receiving_end_date && (
											<CardItem
												key={uniqueId('CardTextItem')}
												title='Дата оконч. приема заявок'
												value={formatDate(purchase.request_receiving_end_date)}
											/>
										)}
									</CardRightStyle>
								</WrapperInfoBlock>
							</WrapperCardStyle>
						)
					})}
				</WrapperPurchase>

				<WrapperStage in={showStages} unmountOnExit showStages={showStages}>
					<div className='stages_history'>
						{listStages.map((stage, i) => {
							return (
								<div className='stage' key={stage.created_at}>
									{stage.stage} - <DateFromTimestamp timestamp={stage.created_at} />
								</div>
							)
						})}
					</div>
				</WrapperStage>

				<CardCollapse isExpanded={details}>
					<CardDetailedInfo fullBlock={toggleType === ProjectType.PURCHASES}>
						{itemsDetailsLeft.map(item => item)}
					</CardDetailedInfo>

					{toggleType === ProjectType.PROJECTS && (
						<div className='card-container__detailed-price'>
							{collapseItemsRightColumn(project)
								.filter((item: any) => !hasUndefinedKeys(item) && item.value)
								.map(({ title, value }: any) => (
									<CardItem key={uniqueId('CardTextItem')} title={title} value={value} />
								))}
						</div>
					)}
				</CardCollapse>
			</Card>
		)
	}
)
export { Item }
