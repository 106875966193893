import { IUnc } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import { ReactText } from 'react'
import { ICommonProject, IUncInfo } from './common-projects.model'
import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'
import { IFormData } from './cost-works.state'

export enum CommonProjectsType {
	GET_COMMON_PROJECTS = 'GET_COMMON_PROJECTS',
	GET_FILTER_MIN_MAX_VALUES = 'GET_FILTER_MIN_MAX_VALUES',
	SET_FILTER_MIN_MAX_VALUES = 'SET_FILTER_MIN_MAX_VALUES',
	APPEND_COMMON_PROJECTS = 'APPEND_COMMON_PROJECTS',
	SET_COMMON_PROJECTS = 'SET_COMMON_PROJECTS',
	ADD_COMMON_PROJECTS = 'ADD_COMMON_PROJECTS',
	SET_COMMON_PROJECTS_COUNT = 'SET_PROJECTS_COUNT',
	SET_COMMON_PROJECTS_PAGINATION_COUNT = 'SET_COMMON_PROJECTS_PAGINATION_COUNT',
	LOADING_COMMON_PROJECTS = 'LOADING_COMMON_PROJECTS',
	DOWNLOAD_COMMON_PROJECT = 'DOWNLOAD_COMMON_PROJECT',
	EDIT_TOGGLE_TYPE = 'EDIT_TOGGLE_TYPE',
	SET_COMMON_PROJECTS_SUM = 'SET_COMMON_PROJECTS_SUM',
	SORT_COMMON_PROJECTS = 'SORT_COMMON_PROJECTS',
	COMMON_PROJECTS_SELECTED_PROJECT = 'COMMON_PROJECTS_SELECTED_PROJECT',
	GET_UNCS_COMMON_PROJECT = 'GET_UNCS_COMMON_PROJECT',
	SET_UNCS_COMMON_PROJECT = 'SET_UNCS_COMMON_PROJECT',
	GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT = 'GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT',
	SET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT = 'SET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT',
	DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT = 'DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT',
	SELECT_COMMON_PROJECT = 'SELECT_COMMON_PROJECT',
	SET_UNC_INFO = 'SET_UNC_INFO',
	ADD_TO_USER_PROJECT = 'ADD_TO_USER_PROJECT',
	REQUEST_RG_STATUSES = 'REQUEST_RG_STATUSES',
	SET_ALLOW_MORE_PROJECTS = 'SET_ALLOW_MORE_PROJECTS',
	REPORT_REQUEST = 'REPORT_REQUEST',
	USERINFO_REQUEST = 'USERINFO_REQUEST',
	REPORT_LOADING = 'REPORT_LOADING',

	SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST',
	SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS',
	SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE',

	GET_ALL_FAVORITE_COMMON_PROJECTS = 'GET_ALL_FAVORITE_COMMON_PROJECTS',
	ACTIONS_FAVORITE_COMMON_PROJECTS = 'ACTIONS_FAVORITE_COMMON_PROJECTS',
	GET_FAVORITES_PROJECTS_AND_REMOVE_PURCHASES = 'GET_FAVORITES_PROJECTS_AND_REMOVE_PURCHASES',
	SET_FILTER_IDS_COMMON_PROJECTS = 'SET_FILTER_IDS_COMMON_PROJECTS',
}

interface ActionsFavoriteCommonProjects {
	type: CommonProjectsType.ACTIONS_FAVORITE_COMMON_PROJECTS
	projectId: string | 'multiple'
	action: 'DELETE' | 'POST'
}

interface GetFavoritesProjectsAndRemovePurchases {
	type: CommonProjectsType.GET_FAVORITES_PROJECTS_AND_REMOVE_PURCHASES
	projectId: string | 'multiple'
	action: 'DELETE' | 'POST'
}
interface GetCommonProjects {
	type: CommonProjectsType.GET_COMMON_PROJECTS
}

interface SetCommonProjects {
	type: CommonProjectsType.SET_COMMON_PROJECTS
	projects: ICommonProject[]
}
interface AddCommonProjects {
	type: CommonProjectsType.ADD_COMMON_PROJECTS
	projects: ICommonProject[]
}

interface SetCommonProjectsSum {
	type: CommonProjectsType.SET_COMMON_PROJECTS_SUM
	valuationOfferForecastSum: number
	valuationPlanForecastSum: number
}

interface SetCommonProjectsCount {
	type: CommonProjectsType.SET_COMMON_PROJECTS_COUNT
	projectsCount: number
}

interface AppendCommonProjects {
	type: CommonProjectsType.APPEND_COMMON_PROJECTS
	projectsCount: number
}

interface SetCommonProjectsPaginationCount {
	type: CommonProjectsType.SET_COMMON_PROJECTS_PAGINATION_COUNT
	paginationCounter: number
}

interface DownloadUncTechnicalSpecifications {
	type: CommonProjectsType.DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT
	id: ReactText
	code: string
}

interface LoadingCommonProjects {
	type: CommonProjectsType.LOADING_COMMON_PROJECTS
	loading: boolean
}

interface EditToggleType {
	type: CommonProjectsType.EDIT_TOGGLE_TYPE
	toggleType: 'offer' | 'plan'
}

interface SortCommonProjects {
	type: CommonProjectsType.SORT_COMMON_PROJECTS
	name: string
	sortName: string
	order?: boolean
}

interface ReportRequest {
	type: CommonProjectsType.REPORT_REQUEST
	name: string
	sortName: string
	order?: boolean
}

interface UserInfoReport {
	type: CommonProjectsType.USERINFO_REQUEST
	payload: string
}

interface ReportLoading {
	type: CommonProjectsType.REPORT_LOADING
	payload: boolean
}

interface SelectCommonProject {
	type: CommonProjectsType.COMMON_PROJECTS_SELECTED_PROJECT
	selectedProject: ICommonProject | null
}

interface SetUncInfo {
	type: CommonProjectsType.SET_UNC_INFO
	uncInfo: IUncInfo | null
}

interface GetUncsCommonProject {
	type: CommonProjectsType.GET_UNCS_COMMON_PROJECT
	id: string | number
}
interface GetUncTechnicalSpecificationsCommonProject {
	type: CommonProjectsType.GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT
	id: string | number
}
interface SetUncTechnicalSpecificationsCommonProject {
	type: CommonProjectsType.SET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT
	uncTechnicalSpecifications: UncTechnicalSpecifications[]
}

interface GetSelectCommonProject {
	type: CommonProjectsType.SELECT_COMMON_PROJECT
	id: string | number
}

interface SetUncsCommonProject {
	type: CommonProjectsType.SET_UNCS_COMMON_PROJECT
	uncs: IUnc[]
}

interface DownloadCommonProject {
	type: CommonProjectsType.DOWNLOAD_COMMON_PROJECT
	id: string | number
	code: string
}

interface AddToUserProject {
	type: CommonProjectsType.ADD_TO_USER_PROJECT
	projectId: string | number
}

interface RequestRgStatuses {
	type: CommonProjectsType.REQUEST_RG_STATUSES
	projectId: string | number
}

interface SetAllowMoreProjects {
	type: CommonProjectsType.SET_ALLOW_MORE_PROJECTS
	allowMoreProjects: boolean
}
export interface SubscribeRequest {
	type: CommonProjectsType.SUBSCRIBE_REQUEST
	data: IFormData
}

export interface SubscribeSuccess {
	type: CommonProjectsType.SUBSCRIBE_SUCCESS
	result: unknown
}

export interface SubscribeFailure {
	type: CommonProjectsType.SUBSCRIBE_FAILURE
	message: string
}

export interface SetFilterIdsCommonProjects {
	type: CommonProjectsType.SET_FILTER_IDS_COMMON_PROJECTS
	IDs: string[]
}

export type CommonProjectsTypes =
	| GetCommonProjects
	| SetCommonProjectsSum
	| SetCommonProjects
	| SetCommonProjectsCount
	| AppendCommonProjects
	| SetCommonProjectsPaginationCount
	| LoadingCommonProjects
	| EditToggleType
	| SortCommonProjects
	| ReportRequest
	| UserInfoReport
	| SelectCommonProject
	| GetUncsCommonProject
	| SetUncInfo
	| GetSelectCommonProject
	| SetUncsCommonProject
	| DownloadCommonProject
	| AddToUserProject
	| RequestRgStatuses
	| SetAllowMoreProjects
	| SetUncTechnicalSpecificationsCommonProject
	| GetUncTechnicalSpecificationsCommonProject
	| DownloadUncTechnicalSpecifications
	| SubscribeRequest
	| SubscribeFailure
	| SubscribeSuccess
	| ActionsFavoriteCommonProjects
	| SetFilterIdsCommonProjects
	| ReportLoading
	| GetFavoritesProjectsAndRemovePurchases
	| AddCommonProjects
