import React, { FC, useState, useContext, useRef, useMemo, useCallback, ReactText, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RowCells } from '@root-gipro/modules/userProjects/components/Project/UncTable/RowCells'
import { Comment, Region, Voltage } from '@root-gipro/modules/userProjects/components/Project/DropDown'
import { IRegion } from '@root-gipro/store/interfaces'
import { ModalContext } from '@root-gipro/context/modal-context'
import { changeComment, changeRegion } from '@root-gipro/modules/userProjects/store/actions'
import { cellTags } from '@root-gipro/modules/userProjects/models/consts/unc-cell-tags.const'
import { ITbodyProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'
import { tbodyArr, TbodyArrType } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { IState } from '@root-gipro/store/interfaces/state'
import { changeVoltage } from '@root-gipro/modules/userProjects/store/actions'
import { getTargetVoltageValues } from '@root-gipro/modules/userProjects/utils/helpers'

const Tbody: FC<ITbodyProps> = ({ uncs }) => {
	const [comment, setComment] = useState<string | undefined>()
	const [checkedRegionId, setCheckedRegion] = useState<ReactText | undefined>()
	const { activeUncs, originalObj } = useSelector((state: IState) => state.userProjects)
	const { regions } = useSelector((state: IState) => state.public)
	const { setAnchorEl, anchorEl, accessoryRef } = useContext(ModalContext)
	const dispatch = useDispatch()
	const voltageRef = useRef<string>()

	const pushCommentPopup = () => {
		const current = accessoryRef && Number(accessoryRef?.current)
		const index = current && current < 0 ? 0 : current

		dispatch(changeComment(activeUncs[index as number].id, comment!))
		setAnchorEl!(null!)
		setComment('')
	}
	const confirmRegionHandler = useCallback(
		regionId => {
			setAnchorEl!(null!)
			activeUncs.forEach(unc => dispatch(changeRegion(unc.id, regionId)))
		},
		[activeUncs]
	)
	const regionList: IRegion[] = useMemo(() => {
		return regions?.filter((elem: IRegion) => originalObj.regionIds.includes(elem.id))
	}, [originalObj, regions])

	useEffect(() => {
		if (anchorEl && anchorEl!.dataset.popup === 'region') setCheckedRegion(anchorEl!.dataset.region)
		if (anchorEl && anchorEl!.dataset.popup === 'comment') {
			const current = accessoryRef && Number(accessoryRef?.current)
			const index = current && current < 0 ? 0 : current

			activeUncs.length !== 0 && setComment(activeUncs[index as number].comment)
		}
	}, [anchorEl])

	return (
		<div>
			{tbodyArr.map((item: TbodyArrType) => (
				<RowCells
					key={item.tableNum}
					tableNum={item.tableNum}
					title={item.title}
					uncs={item.unc(item.tableNum, uncs)}
					currentCellTags={item.tableNum === 8 ? cellTags[1] : cellTags[0]}
					voltageRef={voltageRef}
				/>
			))}

			{anchorEl?.dataset.popup === 'comment' && (
				<Comment
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					comment={comment}
					setComment={setComment}
					handler={pushCommentPopup}
				/>
			)}
			{anchorEl?.dataset.popup === 'region' && (
				<Region
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					regionList={regionList}
					handler={() => confirmRegionHandler(checkedRegionId)}
					checkedRegionId={checkedRegionId!}
					setCheckedRegion={setCheckedRegion}
				/>
			)}
			{anchorEl?.dataset.popup === 'volt-unc-table' && (
				<Voltage
					voltageRef={voltageRef}
					uncVoltage={voltageRef.current}
					targetVoltageValues={getTargetVoltageValues(uncs, anchorEl?.dataset.uncid)}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl!}
					handler={(voltage: number) => {
						dispatch(changeVoltage(accessoryRef?.current as string, voltage, false))
						setAnchorEl!(null!)
					}}
				/>
			)}
		</div>
	)
}
export { Tbody }
