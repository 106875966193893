import { Action } from 'redux'
import { IRefreshList } from '@root-gipro/modules/filter/store/actions'
import { IFilterRates, IFilterState, IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isShowFilterCloud } from '../../store/actions'

export enum FilterActions {
	SET_CALLBACKS = 'FILTER/SET_CALLBACKS',
	REFRESH_FILTER_STATE = 'REFRESH_FILTER_STATE',
	SHOW_FILTER_RESULT = 'FILTER/SHOW_FILTER_RESULT',
	SET_FILTER_SORT_PARAMS = 'FILTER/SET_FILTER_SORT_PARAMS',
	SET_FILTER_RESULT_COUNT = 'FILTER/SET_FILTER_RESULT_COUNT',
	RESET_FILTER_STATE = 'FILTER/RESET_FILTER_STATE',
	SET_INIT_FILTER_STATE = 'FILTER/SET_INIT_FILTER_STATE',
	SET_INIT_RESULT_FILTER_STATE = 'FILTER/SET_INIT_RESULT_FILTER_STATE',
	SET_FILTER_STATE = 'FILTER/SET_FILTER_STATE',
	REPLACE_FILTER_PARAMS = 'FILTER/REPLACE_FILTER_PARAMS',

	FILTER_SET_RESULT = 'FILTER_SET_RESULT',
	REFRESH_FILTER_COMMON_PROJECT_STATE = 'FILTER/REFRESH_FILTER_COMMON_PROJECT_STATE',
	FILTER_REQUEST = 'FILTER_REQUEST',

	SET_FILTER_COMMON_PROJECTS_RATES = 'SET_FILTER_COMMON_PROJECTS_RATES',
	RESET_FILTER_COMMON_PROJECTS_RATES = 'RESET_FILTER_COMMON_PROJECTS_RATES',
	IS_LOADING_COUNT_RESULT = 'IS_LOADING_COUNT_RESULT',
	IS_SHOW_FILTER_CLOUD = 'IS_SHOW_FILTER_CLOUD',
}

interface SetCallbacks {
	type: FilterActions.SET_CALLBACKS
	getInitList: () => void
	showFilterResult: (params: IParams) => void
	refreshList: (projects: IRefreshList[]) => Action
	toggleLoadingList: (loading: boolean) => Action
}

interface FiltersRequest {
	type: FilterActions.FILTER_REQUEST
	url: string
}

interface FilterSetResult {
	type: FilterActions.FILTER_SET_RESULT
}

interface SetFilterSortParams {
	type: FilterActions.SET_FILTER_SORT_PARAMS
	params: any
	url: string
}

interface RefreshFilterState {
	type: FilterActions.REFRESH_FILTER_STATE
	fieldName: string
	value: string
	url: string
	convertValue: (fieldName: string, value: string) => { fieldName: string; value: string }
}
interface ShowFilterResult {
	type: FilterActions.SHOW_FILTER_RESULT
	url: string
}

interface ResetFilterState {
	type: FilterActions.RESET_FILTER_STATE
	url: string
}

interface SetInitFilterState {
	revertFilterValue: any
	type: FilterActions.SET_INIT_FILTER_STATE
	state: any
}

interface SetFilterState {
	type: FilterActions.SET_FILTER_STATE
	state: IFilterState
}

interface SetFilterResultCount {
	type: FilterActions.SET_FILTER_RESULT_COUNT
	count: number
	url: string
}

interface SetFilterCommonProjectsRates {
	type: FilterActions.SET_FILTER_COMMON_PROJECTS_RATES
	data: IFilterRates
}

interface ResetFilterCommonProjectsRates {
	type: FilterActions.RESET_FILTER_COMMON_PROJECTS_RATES
}
interface IsLoadingCountResult {
	type: FilterActions.IS_LOADING_COUNT_RESULT
	isLoading: boolean
}
interface IsShowFilterCloud {
	type: FilterActions.IS_SHOW_FILTER_CLOUD
	isShowFilterCloud: boolean
}

export type FilterTypes =
	| ShowFilterResult
	| RefreshFilterState
	| SetFilterSortParams
	| SetCallbacks
	| FiltersRequest
	| FilterSetResult
	| ResetFilterState
	| SetInitFilterState
	| SetFilterState
	| SetFilterResultCount
	| SetFilterCommonProjectsRates
	| ResetFilterCommonProjectsRates
	| IsLoadingCountResult
	| IsShowFilterCloud
