import React, { useEffect, useState } from 'react'
import { Filter as FilterWrapper, FilterItemGroup } from '@root-gipro/shared/Filter'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'
import { capitalizeFirstLetter, formatDate } from '@root-gipro/utils/helpers/common'
import { isEqual, uniq, uniqBy } from 'lodash'
import { FilterProps } from '@root-gipro/modules/filter/models/props/filter.model'
import { useLocation } from 'react-router-dom'
import { IGroup, IRegion, ICompany, IState } from '@root-gipro/store/interfaces'
import { Box, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import { useDispatch, useSelector } from 'react-redux'
import TooltipHelp from '@root-gipro/shared/Tooltips/TooltipHelp'

const useStyleRadio = makeStyles(theme => ({
	root: {
		margin: '0',
		gap: '4px',
		'& .MuiTypography-body1': {
			fontSize: '14px',
			lineHeight: 1.5,
		},
	},
}))

const priceRadioInfo: { [key: string]: { [key: string]: string } } = {
	priceUnc: {
		name: 'По УНЦ',
		fieldNameStartPurchases: 'project.valuationUncOfferForecast.min',
		fieldNameStartCommonProjects: 'valuationUncOfferForecast.min',
		fieldNameEndPurchases: 'project.valuationUncOfferForecast.max',
		fieldNameEndCommonProjects: 'valuationUncOfferForecast.max',
		isDisablePurchases: 'project.valuationUncOfferForecast',
		isDisableCommonProjects: 'valuationUncOfferForecast',
	},
	priceForecast: {
		name: 'Остаток финансирования в прогнозных ценах',
		fieldNameStartPurchases: 'project.valuationOfferForecast.min',
		fieldNameStartCommonProjects: 'valuationOfferForecast.min',
		fieldNameEndPurchases: 'project.valuationOfferForecast.max',
		fieldNameEndCommonProjects: 'valuationOfferForecast.max',
		isDisablePurchases: 'project.valuationUncOfferForecast',
		isDisableCommonProjects: 'valuationUncOfferForecast',
	},
	pricePsd: {
		name: 'Полная сметная стоимость согласно ПСД (с НДС)',
		fieldNameStartPurchases: 'project.estimatedOfferCurrent.min',
		fieldNameStartCommonProjects: 'estimatedOfferCurrent.min',
		fieldNameEndPurchases: 'project.estimatedOfferCurrent.max',
		fieldNameEndCommonProjects: 'estimatedOfferCurrent.max',
		isDisablePurchases: 'project.estimatedOfferYM',
		isDisableCommonProjects: 'estimatedOfferYM',
	},
}

const Filter: React.FC<FilterProps> = ({
	resetFilter,
	refreshFilterState,
	showFilterResult,
	filterCountResult,
	filterState,
	purchasesType,
	uncInfo,
	purchasesMethods,
	purchasesStages,
	purchasesOkpd,
	purchasesOkved,
	regions,
	groups,
	company,
	rgStatuses,
	publicationDates,
	purchasePublishDates,
	uncThs,
	uncThDescriptions,
	uncMains,
	minMaxValues,
	policesRGCO,
	uncTechnicalSpecifications,
}) => {
	const classes = useStyleRadio()
	const uncMainRows = (): string[] => {
		const arrNumbers = uncMains && uncMains.map(elem => elem.rowsCnt)
		const maxNumber = Math.max(...(arrNumbers ?? []))
		const uniqArrNumbers = uniq(arrNumbers)
			.sort((a: number, b: number) => a - b)
			.map(item => item.toString())
		return uniqArrNumbers
		// return Array.from({ length: maxNumber }, (_, i) => (i + 1).toString())
	}

	const [currentFilter, setCurrentFilter] = useState<boolean>(false)
	const [priceValue, setPriceValue] = useState<string>('priceWithout')

	let location = useLocation()
	const url: string = location.pathname.slice(1)
	const resultFilterState = useSelector((state: IState) => state.filter.commonProjects.resultFilterState)
	useEffect(() => {
		if (Object.keys(resultFilterState).length === 0) {
			setPriceValue('priceWithout')
		}
	}, [resultFilterState])
	useEffect(() => {
		setCurrentFilter(url === 'purchases' || url === 'favorites-purchases' ? true : false)
	}, [url])
	const uncMainColumns = uniq(uncMains.map(elem => elem.colsCnt))
		.sort((a: number, b: number) => a - b)
		.map(item => item.toString())
	// uncMains
	// ?.map(elem => elem.colsCnt)
	// ?.map(elem => ({
	// 	name: elem.colsCnt,
	// 	id: elem.id,
	// }))
	// .filter(i => i.name !== 0)
	// .sort((a, b) => a.name - b.name)
	// .map(i => i.name.toString()) || []

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if ((value !== undefined || value !== '') && !isEqual(value, (filterState as Record<string, string>)[fieldName])) {
			if (fieldName === 'uncTechnicalSpecification.name') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.paramName') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.param') {
			}
			if (filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name']?.length > 1) {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			refreshFilterState!(fieldName, value, url)
		}
	}

	const handleChangePrice = (value: string) => {
		if (value === 'priceWithout') {
			refreshFilterValue('priceWithout', '0')
		}
		setPriceValue(value)
	}

	return (
		<FilterWrapper resultCnt={filterCountResult} resetFilterHandler={resetFilter} showFilterResult={showFilterResult}>
			<FilterItemGroup tagsFilter={['user-projects']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='С подробными техническими характеристиками'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsUNC'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases', 'favorites-purchases']} name='Закупка'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName='registration_number'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName='subject'
					setValue={refreshFilterValue}
				/>
				{/*
				<FilterItem
					name='Закупка в электронной форме'
					filterState={filterState}
					mode='checkbox'
					fieldName='plan.isElectronic'
					setValue={refreshFilterValue}
				/>
				*/}
				<FilterItem
					name='Только проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases', 'favorites-purchases']} name='Параметры закупки'>
				<FilterItem
					isMultiple
					placeholder='Способ определения поставщика (подрядчика, исполнителя)'
					filterState={filterState}
					mode='select'
					fieldName='type'
					setValue={refreshFilterValue}
					items={
						purchasesMethods && purchasesMethods.length > 0
							? purchasesMethods.filter(method => method).map(method => capitalizeFirstLetter(method))
							: []
					}
				/>
				<FilterItem
					isMultiple
					placeholder='Тип закупки'
					filterState={filterState}
					mode='select'
					fieldName={`kind`}
					setValue={refreshFilterValue}
					items={['Товар', 'Работа', 'Услуга']}
				/>
				<FilterItem
					isMultiple
					placeholder='Статус закупки'
					filterState={filterState}
					mode='select'
					fieldName='status'
					setValue={refreshFilterValue}
					items={
						purchasesStages && purchasesStages.length > 0
							? purchasesStages.filter(stage => stage).map(stage => capitalizeFirstLetter(stage))
							: []
					}
				/>
				<FilterItem
					isMultiple
					placeholder='ОКПД'
					filterState={filterState}
					mode='select'
					fieldName='okpd2'
					setValue={refreshFilterValue}
					items={
						purchasesOkpd && purchasesOkpd.length > 0
							? purchasesOkpd.filter(okpd => okpd.code).map(okpd => okpd.code)
							: []
					}
				/>
				<FilterItem
					isMultiple
					placeholder='ОКВЭД'
					filterState={filterState}
					mode='select'
					fieldName='okved2'
					setValue={refreshFilterValue}
					items={
						purchasesOkved && purchasesOkved.length > 0
							? purchasesOkved.filter(okved => okved.code).map(okved => okved.code)
							: []
					}
				/>
				{/* <FilterItem
					name='Начальная цена договора'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={`${purchasesType === 'plan' ? 'plan.maximumPrice.min' : 'notice.initialSum.min'}`}
					fieldNameEnd={`${purchasesType === 'plan' ? 'plan.maximumPrice.max' : 'notice.initialSum.max'}`}
					setValue={refreshFilterValue}
				/> */}
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['purchases', 'common-projects', 'favorites-common-projects', 'favorites-purchases']}
				name='Организация'
			>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='search-checkbox'
					fieldName={currentFilter ? 'company_id' : 'groupId'}
					setValue={refreshFilterValue}
					items={
						currentFilter
							? company
									?.map((company: ICompany) => ({
										name: company.name,
										id: company.id,
										projectsCount: company.projectsCount,
									}))
									.sort() || []
							: groups
									?.map((group: IGroup) => ({ name: group.name, id: group.id, projectsCount: group.projectsCount }))
									.sort() || []
					}
				/>
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['purchases', 'common-projects', 'favorites-common-projects', 'favorites-purchases']}
				name='Регион'
			>
				<FilterItem
					isMultiple
					placeholder='Регион'
					filterState={filterState}
					mode='search-checkbox'
					fieldName={currentFilter ? `region_code` : 'regionId'}
					setValue={refreshFilterValue}
					// items={regions?.map((region: IRegion) => region.name) || []}
					items={
						regions?.map((region: IRegion) => ({
							name: region.name,
							id: region.id,
							projectsCount: region.projectsCount,
						})) || []
					}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Период'>
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					minMax={minMaxValues}
					fieldNameStart='yearBegin.min'
					fieldNameEnd='yearEndOffer.max'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Стоимость проекта'>
				<RadioGroup
					aria-labelledby='demo-radio-buttons-group-label'
					value={priceValue}
					className='aside-filter__radioPrice'
					onChange={e => handleChangePrice(e.target.value)}
					name='radio-buttons-group'
				>
					<FormControlLabel
						value='priceWithout'
						control={<Radio color='primary' size='small' />}
						classes={classes}
						label='Не учитывать стоимость'
					/>
					<Box className='aside-filter__radioPrice-item'>
						<FormControlLabel
							value='pricePsd'
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Полная сметная стоимость согласно ПСД (с НДС)'
						/>
						<TooltipHelp title='Метод определения стоимости инвестиционного проекта на основании существующей проектно-сметной документации'></TooltipHelp>
					</Box>
					<Box className='aside-filter__radioPrice-item'>
						<FormControlLabel
							value='priceUnc'
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Оценка полной стоимости по УНЦ'
						/>
						<TooltipHelp title='Метод определения стоимости инвестиционного проекта с применением справочника укрупненных нормативов цены типовых технологических решений капитального строительства объектов электроэнергетики в части объектов электросетевого хозяйства, утвержденного приказом Министерства энергетики РФ от 17.01.2019г. №10'></TooltipHelp>
					</Box>
					<Box className='aside-filter__radioPrice-item'>
						<FormControlLabel
							value='priceForecast'
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Оценка в прогнозных ценах'
						/>
						<TooltipHelp title='Метод определения прогнозной стоимости инвестиционного проекта по Приказу Министерства энергетики РФ от 5 мая 2016 г. №380'></TooltipHelp>
					</Box>
				</RadioGroup>
				{priceValue !== 'priceWithout' && (
					<FilterItem
						name={priceRadioInfo[priceValue].name}
						filterState={filterState}
						mode='range-numbers'
						minMax={minMaxValues}
						fieldNameStart={
							currentFilter
								? priceRadioInfo[priceValue].fieldNameStartPurchases
								: priceRadioInfo[priceValue].fieldNameStartCommonProjects
						}
						fieldNameEnd={
							currentFilter
								? priceRadioInfo[priceValue].fieldNameEndPurchases
								: priceRadioInfo[priceValue].fieldNameEndCommonProjects
						}
						setValue={refreshFilterValue}
						// isDisable={
						// 	currentFilter
						// 		? Boolean(filterState[priceRadioInfo[priceValue].isDisablePurchases])
						// 		: Boolean(filterState[priceRadioInfo[priceValue].isDisableCommonProjects])
						// }
					/>
				)}
				{/* {priceValue === 'priceUnc' ? (
					<FilterItem
						name='По УНЦ'
						filterState={filterState}
						mode='range-numbers'
						minMax={minMaxValues}
						fieldNameStart={currentFilter ? 'project.valuationUncOfferForecast.min' : 'valuationUncOfferForecast.min'}
						fieldNameEnd={currentFilter ? 'project.valuationUncOfferForecast.max' : 'valuationUncOfferForecast.max'}
						setValue={refreshFilterValue}
						isDisable={
							currentFilter
								? Boolean(filterState['project.valuationUncOfferForecast'])
								: Boolean(filterState['valuationUncOfferForecast'])
						}
					/>
				) : null}
				{priceValue === 'priceForecast' ? (
					<FilterItem
						name='Остаток финансирования в прогнозных ценах'
						filterState={filterState}
						mode='range-numbers'
						fieldNameStart={currentFilter ? 'project.valuationOfferForecast.min' : 'valuationOfferForecast.min'}
						fieldNameEnd={currentFilter ? 'project.valuationOfferForecast.max' : 'valuationOfferForecast.max'}
						setValue={refreshFilterValue}
					/>
				) : null}
				{priceValue === 'pricePsd' ? (
					<FilterItem
						name='Полная сметная стоимость согласно ПСД (с НДС)'
						filterState={filterState}
						mode='range-numbers'
						minMax={minMaxValues}
						fieldNameStart={currentFilter ? 'project.estimatedOfferCurrent.min' : 'estimatedOfferCurrent.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferCurrent.max' : 'estimatedOfferCurrent.max'}
						setValue={refreshFilterValue}
						isDisable={
							currentFilter
								? Boolean(filterState['project.estimatedOfferYM'])
								: Boolean(filterState['estimatedOfferYM'])
						}
					/>
				) : null} */}

				{!filterState['estimatedOfferYM'] &&
				(priceValue === 'priceUnc' || priceValue === 'priceForecast' || priceValue === 'pricePsd') ? (
					<FilterItem
						name='Дата составления ПСД'
						filterState={filterState}
						mode='dates-range'
						minMax={minMaxValues}
						fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
						setValue={refreshFilterValue}
						dateOpenTo='year'
						dateViews={['year']}
						//isDisable={true}

						// withCheckbox={{
						// 	checked: currentFilter
						// 		? Boolean(filterState['project.estimatedOfferYM']) || false
						// 		: Boolean(filterState['estimatedOfferYM']) || false,
						// 	setChecked: (value: boolean) =>
						// 		currentFilter
						// 			? refreshFilterValue('project.estimatedOfferYM', value)
						// 			: refreshFilterValue('estimatedOfferYM', value),
						// 	label: 'Без ПСД',
						// 	checkboxChange: () =>
						// 		currentFilter
						// 			? refreshFilterValue('project.estimatedOfferYM', filterState['project.estimatedOfferYM'])
						// 			: refreshFilterValue('estimatedOfferYM', filterState['estimatedOfferYM'])
						// }}
					/>
				) : null}
				{/* 
				<FilterItem
					name='Без ПСД'
					filterState={filterState}
					mode='checkbox'
					fieldName='estimatedOfferYM'
					setValue={refreshFilterValue}
				/> */}
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Остаток финансирования'>
				<FilterItem
					name='Остаток финансирования'
					filterState={filterState}
					mode='range-numbers'
					minMax={minMaxValues}
					fieldNameStart={currentFilter ? 'project.balanceOffer.min' : 'balanceOffer.min'}
					fieldNameEnd={currentFilter ? 'project.balanceOffer.max' : 'balanceOffer.max'}
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Строительные ресурсы'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='search-radio'
					fieldName={'uncTechnicalSpecification.name'}
					setValue={refreshFilterValue}
					items={uncTechnicalSpecifications?.name ? Object.values(uncTechnicalSpecifications?.name) : []}
				/>
				{filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name'].length < 2 && (
					<FilterItem
						isMultiple
						placeholder='Параметры'
						filterState={filterState}
						mode='search-radio'
						fieldName={'uncTechnicalSpecification.paramName'}
						setValue={refreshFilterValue}
						items={uncTechnicalSpecifications?.paramName ? Object.values(uncTechnicalSpecifications?.paramName) : []}
					/>
				)}
				{filterState['uncTechnicalSpecification.paramName'] && filterState['uncTechnicalSpecification.name'] && (
					<FilterItem
						isMultiple
						placeholder='Значение'
						filterState={filterState}
						mode='search-radio'
						fieldName={'uncTechnicalSpecification.param'}
						setValue={refreshFilterValue}
						items={uncTechnicalSpecifications?.param ? Object.values(uncTechnicalSpecifications?.param) : []}
					/>
				)}
				{filterState['uncTechnicalSpecification.paramName'] &&
					filterState['uncTechnicalSpecification.name'] &&
					filterState['uncTechnicalSpecification.param'] && (
						<FilterItem
							name='Количество'
							filterState={filterState}
							mode='range-numbers'
							minMax={minMaxValues}
							fieldNameStart='unc.count.min'
							fieldNameEnd='unc.count.max'
							setValue={refreshFilterValue}
						/>
					)}
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['purchases', 'favorites-purchases']} name='Период'>
				<FilterItem
					isMultiple
					placeholder='Дата публикации закупки на площадке'
					filterState={filterState}
					mode='select'
					fieldName='publish_date'
					setValue={refreshFilterValue}
					items={purchasePublishDates.map((date: string) => formatDate(date, true)!) || []}
				/>
				{/*
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='plan.startDate'
					fieldNameEnd='plan.endDate'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
				*/}
				{/*
				<FilterItem
					name='Планируемая дата размещения извещения'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='plan.plannedDate.min'
					fieldNameEnd='plan.plannedDate.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
				*/}
				{/*
				<FilterItem
					name='Срок исполнения договора'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart={`${purchasesType === 'plan' ? 'plan.endDate.min' : 'notice.summingUpDate.min'}`}
					fieldNameEnd={`${purchasesType === 'plan' ? 'plan.endDate.max' : 'notice.summingUpDate.max'}`}
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
				*/}
				{/*
				<FilterItem
					name='Дата размещения тек. редакции извещения'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='notice.publicationDate.min'
					fieldNameEnd='notice.publicationDate.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month']}
				/>
				*/}
				<FilterItem
					name='Дата окончания подачи заявок'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='request_receiving_end_date.min'
					fieldNameEnd='request_receiving_end_date.max'
					setValue={refreshFilterValue}
					dateFormat='MM.yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
			</FilterItemGroup>

			<FilterItemGroup
				tagsFilter={['purchases', 'favorites-purchases']}
				name='Начальная максимальная цена, млн руб. с НДС'
			>
				<FilterItem
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart='price.min'
					fieldNameEnd='price.max'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>

			{/* <FilterItemGroup
				tagsFilter={['common-projects', 'favorites-common-projects']}
				name='Полная сметная стоимость согласно ПСД (с НДС)'
			>
				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Стоимость'
						filterState={filterState}
						mode='range-numbers'
						fieldNameStart={currentFilter ? 'project.estimatedOfferCurrent.min' : 'estimatedOfferCurrent.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferCurrent.max' : 'estimatedOfferCurrent.max'}
						setValue={refreshFilterValue}
						isDisable={
							currentFilter
								? Boolean(filterState['project.estimatedOfferYM'])
								: Boolean(filterState['estimatedOfferYM'])
						}
					/>
				)}

				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Дата составления'
						filterState={filterState}
						mode='dates-range'
						fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
						setValue={refreshFilterValue}
						dateOpenTo='year'
						dateViews={['year', 'month']}
						//isDisable={true}

						// withCheckbox={{
						// 	checked: currentFilter
						// 		? Boolean(filterState['project.estimatedOfferYM']) || false
						// 		: Boolean(filterState['estimatedOfferYM']) || false,
						// 	setChecked: (value: boolean) =>
						// 		currentFilter
						// 			? refreshFilterValue('project.estimatedOfferYM', value)
						// 			: refreshFilterValue('estimatedOfferYM', value),
						// 	label: 'Без ПСД',
						// 	checkboxChange: () =>
						// 		currentFilter
						// 			? refreshFilterValue('project.estimatedOfferYM', filterState['project.estimatedOfferYM'])
						// 			: refreshFilterValue('estimatedOfferYM', filterState['estimatedOfferYM'])
						// }}
					/>
				)}

				<FilterItem
					name='Без ПСД'
					filterState={filterState}
					mode='checkbox'
					fieldName='estimatedOfferYM'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup> */}
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Технические решения'>
				<FilterItem
					placeholder='Наименование'
					filterState={filterState}
					mode='search-checkbox'
					fieldName={currentFilter ? 'project.uncCell.uncThId' : 'uncCell.uncThId'}
					setValue={refreshFilterValue}
					// items={uncThs.map(unc => unc.name).filter(name => name) || []}
					items={uncThs.map(unc => ({ name: unc.name, id: unc.id })).filter(name => name) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Параметры'
					filterState={filterState}
					mode='search-checkbox'
					fieldName={currentFilter ? 'project.uncCell.uncTextParameterId' : 'uncCell.uncTextParameterId'}
					setValue={refreshFilterValue}
					// items={uncThDescriptions.filter(elem => elem.title).map(param => param.title) || []}
					items={uncThDescriptions.map(param => ({ name: param.title, id: param.id })) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Напряжение, кВ'
					filterState={filterState}
					mode='search-checkbox-unc'
					fieldName={currentFilter ? 'project.uncCell.voltageValue' : 'uncCell.voltageValue'}
					setValue={refreshFilterValue}
					items={uncInfo?.voltageValues.filter((elem: string) => elem) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>

				<FilterItem
					isMultiple
					placeholder='Таблица'
					filterState={filterState}
					mode='search-checkbox'
					fieldName={currentFilter ? 'project.uncCell.uncMainId' : 'uncCell.uncMainId'}
					setValue={refreshFilterValue}
					// items={uncMains?.map(elem => elem.code) || []}
					items={uncMains?.map(elem => ({ name: elem.code, id: elem.id })) || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Строка'
					filterState={filterState}
					mode='search-checkbox-unc'
					fieldName={currentFilter ? 'project.uncCell.number' : 'uncCell.number'}
					setValue={refreshFilterValue}
					items={uncMainRows() || []}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				<FilterItem
					isMultiple
					placeholder='Столбец'
					filterState={filterState}
					mode='search-checkbox-unc'
					fieldName={currentFilter ? 'project.uncCell.subNumber' : 'uncCell.subNumber'}
					setValue={refreshFilterValue}
					items={uncMainColumns}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>

				<FilterItem
					name='Количество'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.unc.count.min' : 'unc.count.min'}
					fieldNameEnd={currentFilter ? 'project.unc.count.max' : 'unc.count.max'}
					setValue={refreshFilterValue}
					isDisable={currentFilter ? Boolean(filterState['project.noUncs']) : Boolean(filterState['noUncs'])}
				/>
				{policesRGCO && (
					<FilterItem
						isMultiple
						placeholder='Запрос ИД'
						filterState={filterState}
						mode='select'
						fieldName={currentFilter ? 'project.rgStatusId' : 'rgStatusId'}
						setValue={refreshFilterValue}
						items={rgStatuses.map(elem => elem.title)}
						isDisable={Boolean(filterState['project.noUncs']) || Boolean(filterState['noUncs'])}
					/>
				)}
				{/* <FilterItem
					name='Без тех. решения'
					filterState={filterState}
					mode='checkbox'
					fieldName={currentFilter ? 'project.noUncs' : 'noUncs'}
					setValue={refreshFilterValue}
				/> */}
			</FilterItemGroup>
		</FilterWrapper>
	)
}
export default Filter
