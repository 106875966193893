import React, { useEffect, useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import '@root-gipro/shared/Filter/Filter/Filter.scss'
import { FilterProps } from '@root-gipro/shared/Filter/Filter/filter.props'
import { getNoun, lightPretty } from '@root-gipro/utils/helpers/common'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'

import '@root-gipro/shared/Filter/Filter/Filter.scss'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../../store/interfaces/state'
import { isShowFilterCloud } from '@root-gipro/modules/filter/store/actions'

const useStylesBtn = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
		fontWeight: 400,

		'&:hover': {
			backgroundColor: 'transparent',
			fontWeight: 500,
		},
	},
}))

const Filter: React.FC<FilterProps> = ({ resetFilterHandler, showFilterResult, resultCnt, children }) => {
	const routeMatch = useRouteMatch()
	const dispatch = useDispatch()
	const url = routeMatch.path.slice(1)
	let resultName = ''
	const { loadingCountResult } = useSelector((state: IState) => state.filter)
	const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(false)

	const handleFilterResult = useCallback(() => {
		// dispatch(isShowFilterCloud(true))
		showFilterResult()
	}, [resultCnt])

	useEffect(() => {
		// dispatch(isShowFilterCloud(true))
		showFilterResult()
	}, [])

	resultName = Number(resultCnt)
		? `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, ['результат', 'результата', 'результатов'])}`
		: 'Нет результата'

	useEffect(() => {
		if (Number(resultCnt)) {
			resultName = `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, [
				'результат',
				'результата',
				'результатов',
			])}`
			setIsDisabledSubmitBtn(false)
		} else {
			resultName = 'Нет результата'
			setIsDisabledSubmitBtn(true)
		}
	}, [resultCnt])

	const classes = useStylesBtn()

	return (
		<aside data-filter-animate className='aside-filter'>
			<form className='aside-filter__form' onSubmit={() => console.log('sumb')}>
				<div className='aside-filter__form-head'>
					<h2 className='aside-filter__title'>Фильтры</h2>
					<Button
						type='reset'
						variant='text'
						size='small'
						color='primary'
						className='aside-filter__reset link link-blue'
						style={{ textTransform: 'none' }}
						onClick={resetFilterHandler}
						disableFocusRipple
						disableRipple
						classes={classes}
					>
						Сбросить все
					</Button>
				</div>

				{Object.values(children!).filter(item => {
					const { tagsFilter } = item.props
					return tagsFilter.find((tag: string) => {
						if (tag === url) return item
					})
				})}
			</form>
			<div className='aside-filter__result'>
				<PrimaryBtn disabled={isDisabledSubmitBtn} fullWidth type='submit' onClick={handleFilterResult}>
					{loadingCountResult ? <CircularProgress size={16} style={{ color: 'white' }} /> : resultName}
				</PrimaryBtn>
			</div>
		</aside>
	)
}

export default Filter
