import React, { FC, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { CirclePlusSVG } from '@root-gipro/shared/SVGComponents/CirclePlusSVG'
import {
	getProjects,
	setIsNewProject,
	setProjectUncs,
	downloadProject,
	downloadRulesCheck,
	uploaderRulesCheck,
} from '@root-gipro/modules/userProjects/store/actions'
import { createProjectTemplate } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { IconButton } from '@material-ui/core'
import store from '@root-gipro/store'
import { ISortItem } from '@root-gipro/store/interfaces'
import { makeStyles } from '@material-ui/core/styles'
import SortItems from '@root-gipro/shared/FilterHeader/components/sort-items'

const useStyles = makeStyles({
	projectsHeadSortText: {
		color: 'rgba(0, 0, 0, 0.56)',
		fontSize: '16px',
		marginRight: 8,
	},
})

export interface IUserProjectsNav {
	sort?(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
}

const UserProjectsNav: FC<IUserProjectsNav> = memo(({ sort, sortList }) => {
	const { totalInvestmentUncOfferSum, userProjectsCnt, uncs } = useSelector((state: IState) => state.userProjects)
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	// const { roleId } = store.getState().auth.user
	const roleId = store.getState().auth.user.roleId;
	console.log(roleId);

	useEffect(() => {
		dispatch(getProjects())
		dispatch(setIsNewProject(false))
		if (uncs.length > 0) {
			dispatch(setProjectUncs([]))
		}
	}, [])
	return (
		<div className='user-projects__nav'>
			<div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<SecondaryBtn
						className='add_btn'
						onClick={() => {
							dispatch(createProjectTemplate())
							history.push('user-projects/project/0/parameters')
						}}
					>
						<CirclePlusSVG />
						Добавить
					</SecondaryBtn>

					<IconButton onClick={() => dispatch(downloadProject(null, null, 'all'))}>
						<span className='download-icon' title='Скачать Форму 20'></span>
					</IconButton>
					{sort && <span className={classes.projectsHeadSortText}>Сортировка по:</span>}
					{sort && <SortItems sortItems={sort} sortList={sortList} />}
				</div>

				{roleId && roleId <= 10 && (
					<div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8em' }}>
						Правила проверки:&nbsp;
						<button style={{ color: 'black', margin: '0 8px' }} onClick={() => dispatch(downloadRulesCheck())}>
							Скачать
						</button>
						<label style={{ margin: '0 8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							Загрузить
							<input
								type='file'
								style={{ margin: '0 8px', display: 'none' }}
								accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								onChange={e => dispatch(uploaderRulesCheck(e.target.files))}
							/>
						</label>
					</div>
				)}
			</div>

			<div className='user-projects__options'>
				<div className='user-projects__options-item'>
					<span className='user-projects__options-shrt-txt'>Всего: </span>
					<span className='user-projects__options-count'>{userProjectsCnt}</span>
				</div>
				<div className='user-projects__options-item'>
					<span className='user-projects__options-sum'>{prettyCost(totalInvestmentUncOfferSum / 10 ** 6)} млн.р</span>
				</div>
			</div>
		</div>
	)
})

export { UserProjectsNav }
