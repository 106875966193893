// @ts-nocheck
import { createMuiTheme } from '@material-ui/core'

export const appTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#1BAAF0',
			dark: '#1690ce',
			light: '#FFFFFF',
		},
	},
	overrides: {
		MuiAutocomplete: {
			inputRoot: {
				'&[class*="MuiOutlinedInput-root"]': {
					padding: 0,
				},
			},
		},
		MuiInputBase: {
			root: {
				height: 32,
				fontSize: 14,
				transition: 'all 0.15s ease-in-out',
			},
		},
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: '#fff',
				},
			},
			icon: {
				padding: 12,
			},
			outlined: {
				'&.MuiSelect-outlined': {
					paddingRight: 52,
				},
			},
		},
		MuiCollapse: {
			container: {
				width: '100%',
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: '6px',
				transition: 'all 0.15s ease-in-out',
				borderColor: '#1BAAF0',
			},
			input: {
				padding: '8px 32px 8px 8px',
			},
			notchedOutline: {
				borderColor: 'rgba(0, 0, 0, 0.12)',
				transition: 'all 0.15s ease-in-out',
			},
			inputAdornedStart: {
				textAlign: 'end',
				paddingRight: 8,
			},
			adornedStart: {
				paddingLeft: 8,
			},
		},
		MuiInputLabel: {
			root: {
				width: 'calc(100% - 65px)',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				fontSize: 14,
			},
			outlined: {
				transform: ' translate(10px, 10px) scale(1)',
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: 14,
			},
		},
		MuiIconButton: {
			label: {
				width: 18,
				height: 18,
			},
		},
		MuiCheckbox: {
			root: {
				color: '#1BAAF0',
			},
		},
		PrivateSwitchBase: {
			root: {
				padding: 0,
			},
		},
		MuiListItemText: {
			root: {
				fontSize: 14,
				marginLeft: 10,
				marginTop: 0,
				marginBottom: 0,
			},
		},
		MuiPaper: {
			elevation8: {
				boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
			},
		},
		MuiTypography: {
			body1: {
				fontSize: 14,
				lineHeight: '16px',
			},
		},
		MuiPickersDay: {
			daySelected: {
				color: '#fff',
			},
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: '#fff',
			},
			toolbarBtnSelected: {
				color: '#fff',
			},
		},
		MuiTabs: {
			indicator: {
				height: 3,
			},
		},
		MuiTab: {
			root: {
				padding: '8px 16px',
				'@media (min-width: 0px)': {
					minWidth: 0,
				},
			},
			textColorPrimary: {
				color: 'rgba(0, 0, 0, 0.87)',
			},
			wrapper: {
				textTransform: 'initial',
				fontWeight: 'normal',
				fontSize: 16,
			},
		},
		MuiAutocomplete: {
			option: {
				fontSize: 14,
			},
			input: {
				width: 'calc(100% - 65px)',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				fontSize: 14,
				margin: '-10px 0 0 -10px',
			},
		},
	},
})

export const headerTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#1BAAF0',
			dark: '#1690ce',
		},
	},
	overrides: {
		MuiIconButton: {
			root: {
				height: 40,
				width: 40,
			},
		},
		MuiInputBase: {
			root: {
				borderRadius: 3,
				width: 450,
				boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
			},
			input: {
				padding: '8px 0 8px 12px',
			},
		},
		MuiTabs: {
			indicator: {
				height: 3,
			},
		},
		MuiTab: {
			root: {
				padding: '8px 16px',
				'@media (min-width: 0px)': {
					minWidth: 0,
				},
			},
			textColorPrimary: {
				color: '#fff',
			},
			wrapper: {
				textTransform: 'initial',
				fontWeight: '400',
			},
		},
	},
})
