export const objectStylesForCard = [
	{
		id: 1,
		img: 'tariff-purchases.png',
		color: '#01b0e9',
		name: 'Gipro Закупки',
		description: 'Информационно-аналитический ресурс с инвестиционными проектами и закупками энергетики',
		tarifYear: 4400,
		tarif6Month: 4750,
		tarifMonth: 5000,
		tarifYear_unlimit: 17600,
		tarif6Month_unlimit: 19000,
		tarifMonth_unlimit: 20000,
		data: [
			'89 000 проектов строительства до 2029 года',
			'Поиск по строительным ресурсам',
			'Паспорта проектов',
			'Выгрузка аналитических отчетов',
			'Автоматическая проверка расчетов',
		],
		data_unlimit: [
			'89 000 проектов строительства до 2029 года',
			'Поиск по строительным ресурсам',
			'Паспорта проектов',
			'Выгрузка аналитических отчетов',
			'Расчет неограниченного кол-ва проектов',
			'Автоматическая проверка расчетов',
		],
		link: 'https://gi-pro.ru/purchases',
	},
	{
		id: 2,
		img: 'tariff-calculate.png',
		color: '#01b0e9',
		name: 'Gipro Расчёты',
		description:
			'Инструмент автоматизированного расчёта и проверки стоимости реализации проекта по методике с применением УНЦ',
		tarifYear: 4400,
		tarif6Month: 4750,
		tarifMonth: 5000,
		tarifYear_unlimit: 17600,
		tarif6Month_unlimit: 19000,
		tarifMonth_unlimit: 20000,
		data: [
			'89 000 проектов строительства до 2029 года',
			'Поиск по строительным ресурсам',
			'Паспорта проектов',
			'Выгрузка аналитических отчетов',
			'Автоматическая проверка расчетов',
		],
		data_unlimit: [
			'89 000 проектов строительства до 2029 года',
			'Поиск по строительным ресурсам',
			'Паспорта проектов',
			'Выгрузка аналитических отчетов',
			'Расчет неограниченного кол-ва проектов',
			'Автоматическая проверка расчетов',
		],
		link: 'https://gi-pro.ru/calculations',
	},
	{
		id: 3,
		img: 'tariff-reports.png',
		color: '#01b0e9',
		name: 'Gipro Отчеты',
		description:
			'Аналитика рынка энергетики для проектных, строительных организаций, производителей, поставщиков и дистрибьюторов',
		tarifYear: 4400,
		tarif6Month: 4750,
		tarifMonth: 5000,
		tarifYear_unlimit: 17600,
		tarif6Month_unlimit: 19000,
		tarifMonth_unlimit: 20000,
		tariff: 10000,
		tafiffFull: 80000,
		data: [
			'Аналитика рынка энергетики для проектных, строительных организаций, производителей, поставщиков и дистрибьюторов',
		],
		link: 'https://gi-pro.ru/otchety',
	},
]
