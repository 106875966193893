import React, { useEffect, useState } from 'react'
import { Collapse } from '@material-ui/core'
import { FilterItemGroupProps } from '@root-gipro/shared/Filter/models/filter-item-group.props'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import variables from '@root-gipro/styles/config/variables.module.scss'

const FilterItemGroup: React.FC<FilterItemGroupProps> = ({ name, children }) => {
	const [isExpanded, setOpen] = useState<boolean>(false)

	useEffect(() => {
		if (name === 'Проект') {
			setOpen(true)
		}
	}, [name])

	return (
		<div className='aside-filter__item'>
			<div className='aside-filter__item-head'>
				{name !== 'Проект' ? (
					<h4 className={`aside-filter__item-select ${isExpanded ? 'open' : ''}`} onClick={() => setOpen(!isExpanded)}>
						<span className='aside-filter__item-name'>{name}</span>
						{/* <span className='dropdown-icon-bold' /> */}
						{isExpanded ? (
							<ExpandLessIcon style={{ color: variables.grey }}></ExpandLessIcon>
						) : (
							<ExpandMoreIcon style={{ color: variables.grey }}></ExpandMoreIcon>
						)}
					</h4>
				) : null}
			</div>
			<Collapse in={isExpanded} unmountOnExit className='aside-filter__item-dropdown'>
				{children}
			</Collapse>
		</div>
	)
}

export default FilterItemGroup
