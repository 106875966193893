import React, { useEffect, FC } from 'react'
import { Filter } from '@root-gipro/modules/purchases/containers'
import '@root-gipro/modules/purchases/styles/PurchasesContainer.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { IState } from '@root-gipro/store/interfaces/state'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import {
	filtersRequest,
	isLoadingCountResult,
	isShowFilterCloud,
	refreshFilterState,
	resetFilterState,
	setInitFilterState,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { convertFilterValue, revertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import { decode, encode } from '@root-gipro/utils/helpers/queryString'
import { isEqual } from 'lodash'
import '@root-gipro/modules/commonProjects/styles/RangePriceFilter.scss'
import { setPurchasesPaginationCounter } from '../purchases/store/actions'

const FilterContainer: FC = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { countPublicInfoFetches } = useSelector((state: IState) => state.public)
	const publicInfo = useSelector((state: IState) => state.public)
	const { purchasesType } = useSelector((state: IState) => state.purchases)
	const uncInfoCommonProjects = useSelector((state: IState) => state.commonProjects.uncInfo)
	const uncInfoPurchases = useSelector((state: IState) => state.purchases.uncInfo)

	const url: string = location.pathname.slice(1)

	const { getInitList, showFilterResult } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)

	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	if (countPublicInfoFetches >= 13) {
		dispatch(isShowFilterCloud(true))
	}

	useEffect(() => {
		dispatch(
			setInitFilterState(
				decode(location.search),
				revertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)
	}, [countPublicInfoFetches])

	const refresh = (fieldName: string, value: string) => {
		dispatch(isLoadingCountResult(true))
		dispatch(
			refreshFilterState(
				fieldName,
				value,
				url,
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)
	}

	const showResult = () => {
		history.push({
			pathname: location.pathname,
			search: encode(showFilterState),
		})
		if (!!Number(countResult)) dispatch(showFilterResultHandler(url, true))
		dispatch(setPurchasesPaginationCounter(5))
	}
	const reset = () => {
		dispatch(getInitList!())
		dispatch(resetFilterState(url))
		dispatch(filtersRequest(url))
		history.push({
			pathname: location.pathname,
			search: '',
		})
	}
	return (
		<div className='aside-filter-container'>
			<LoaderContainer isLoading={!getInitList}>
				{getInitList && (
					<Filter
						showFilterResult={showResult}
						refreshFilterState={refresh}
						resetFilter={reset}
						filterCountResult={countResult}
						filterState={resultFilterState}
						purchasesType={purchasesType}
						minMaxValues={publicInfo.minMax}
						uncInfo={url === 'purchases' ? uncInfoPurchases : uncInfoCommonProjects}
						{...publicInfo}
						rgStatuses={rgStatuses}
					/>
				)}
			</LoaderContainer>
			{/* <div className='aside-filter-copyright'>
				Copyright 2022 ©<br />
				ООО «Мастерская цифровых решений»
			</div> */}
		</div>
	)
}

export default FilterContainer
