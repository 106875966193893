import React, { useCallback, useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import FilterHeader from '@root-gipro/shared/FilterHeader/FilterHeader'
import { CommonProjectsList } from '@root-gipro/modules/commonProjects/containers'
import UserInfoDiv from '@root-gipro/modules/commonProjects/components/UserInfoDiv'
import {
	editToggleType,
	getCommonProjects,
	sortCommonProjects,
	selectCommonProject,
	actionFavoriteCommonProjects,
	getAllFavoriteCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { setFilterOpen } from '@root-gipro/store/actions/maket'
import { IState } from '@root-gipro/store/interfaces/state'
import { commonProjectsApi } from '@root-gipro/store/api'
import { setCallbacks, setFilterSortParams, filtersRequest } from '@root-gipro/modules/filter/store/actions'
import { loadingCommonProjects, setCommonProjects } from '@root-gipro/modules/commonProjects/store/actions'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import sortList from '@root-gipro/modules/commonProjects/models/const/sort-list.const'
import { toggleFilter } from '@root-gipro/modules/filter/utils/toggle-filter'
import { types } from '@root-gipro/modules/commonProjects/models/const/tabs-params.const'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { ModalContext } from '@root-gipro/context/modal-context'
import { Alert } from '@material-ui/lab'
import ym from 'react-yandex-metrika'
import FilterCloud from '@root-gipro/shared/FilterCloud/FilterCloud'
import FilterCloudPlaceholder from '@root-gipro/shared/FilterCloud/FilterCloudPlaceholder'
import { Box } from '@material-ui/core'

const CommonProjectsContainer = () => {
	let {
		projects,
		toggleType,
		projectsCount,
		loading,
		valuationPlanForecastSum,
		valuationOfferForecastSum,
	} = useSelector((state: IState) => state.commonProjects)
	const location = useLocation()
	const searchUrl: string = location.search
	const { groups, countPublicInfoFetches } = useSelector((state: IState) => state.public)
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const { isFavorites, IDs } = useSelector((state: IState) => state.commonProjects)
	const loadingFavorites = useSelector((state: IState) => state.favorites.loading)
	const userInfo = useSelector((state: IState) => state.commonProjects.userInfo)

	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const url: string = routeMatch.path.slice(1)

	useEffect(() => {
		dispatch(selectCommonProject(null))
		ym('hit', '/common-projects')
	}, [])

	useEffect(() => {
		const params = {
			limit: 10,
			with: [
				'resultsCnt',
				'valuationOfferForecastSum',
				'valuationPlanForecastSum',
				'projects',
				'uncInfo',
				'uncTechnicalSpecifications',
			],
		}
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
	}, [url])

	useEffect(() => {
		setAnchorEl && !loadingFavorites && setAnchorEl(null)
	}, [loadingFavorites])

	const sort = (name: string, sortName: string, order?: boolean | undefined): void => {
		dispatch(sortCommonProjects(name, sortName, order))
	}

	const isShowFilter = useCallback(() => {
		dispatch(setFilterOpen(!isFilterOpen))
		toggleFilter(!isFilterOpen)
	}, [isFilterOpen])

	const handleFavorites = () => {
		dispatch(getAllFavoriteCommonProjects())
	}

	const buttons = {
		PrimaryBtn: {
			handler: () => dispatch(actionFavoriteCommonProjects('multiple', 'POST', 'PROJECTS')),
			value: 'Добавить',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl!(null!)
			},
			value: 'Отмена',
		},
	}

	const generateTextForAlert = useCallback(
		(IDs: string[] | null) => {
			if (!IDs) return
			if (IDs.length >= 100) {
				return (
					<>
						В избранные будет добавлено<strong> не более 100 проектов</strong>
					</>
				)
			}
			return <>В избранное будет добавлено проектов : {IDs.length} шт.</>
		},
		[anchorEl, IDs]
	)

	return (
		<div className='projects-container'>
			<FilterContainer />

			<div data-wrapper-animate className='projects-wrapper'>
				<FilterHeader
					sort={sort}
					sortList={sortList}
					handleFavorites={handleFavorites}
					toggleType={toggleType}
					editToggleType={(toggleType: string) => dispatch(editToggleType(toggleType))}
					projectsCount={projectsCount}
					offerSum={valuationOfferForecastSum}
					planSum={valuationPlanForecastSum}
					types={types}
					isShowFilter={isShowFilter}
					isFavorite={isFavorites}
				/>
				<Box style={{ marginBottom: '15px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
					{searchUrl && countPublicInfoFetches < 13 ? <FilterCloudPlaceholder /> : null}
					{searchUrl && countPublicInfoFetches >= 13 ? <FilterCloud /> : null}
				</Box>

				{userInfo
					? userInfo && <UserInfoDiv data={userInfo} />
					: projects && groups && <CommonProjectsList toggleType={toggleType} projects={projects} />}
			</div>
			{anchorEl?.dataset.favorites && IDs && (
				<Modal
					widthWindow={'400px'}
					buttonOptions={buttons}
					setAnchorEl={setAnchorEl!}
					anchorEl={anchorEl}
					loader={loadingFavorites}
				>
					<Alert severity={IDs.length >= 100 ? 'warning' : 'info'}>{generateTextForAlert(IDs)}</Alert>
				</Modal>
			)}
		</div>
	)
}

export default CommonProjectsContainer
