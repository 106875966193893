import { IFilterState } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'

export const filterState: IFilterState = {
	commonProjects: {
		resultFilterState: {},
		showFilterState: {},
	},
	commonProjectsFavorite: {
		resultFilterState: {},
		showFilterState: {},
	},
	purchases: {
		resultFilterState: {},
		showFilterState: {},
	},
	purchasesFavorite: {
		resultFilterState: {},
		showFilterState: {},
	},
	userProjects: {
		resultFilterState: {},
		showFilterState: {},
	},

	commonProjectsRates: {
		countStart: '',
		countEnd: '',
		name: [],
		params: [],
		voltage: [],
		table: [],
		row: [],
		column: [],
	},
	loadingCountResult: false,
	isShowFilterCloud: false,
}
