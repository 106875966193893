import React, { FC, useEffect } from 'react'
import List from '@root-gipro/modules/userProjects/components/UserProjects/List'
import { HeaderWrapper } from '@root-gipro/modules/userProjects/components/HeaderWrapper'
import { UserProjectsNav } from '@root-gipro/modules/userProjects/components/UserProjects/UserProjectsNav'
import '@root-gipro/modules/userProjects/styles/UserProjects.scss'
import { Text, TextLink, WrapperInformation } from './styles'
import store from '@root-gipro/store'
import ym from 'react-yandex-metrika'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import { useDispatch, useSelector } from 'react-redux'
import { filtersRequest, setCallbacks, setFilterSortParams } from '@root-gipro/modules/filter/store/actions'
import { useRouteMatch } from 'react-router-dom'
import {
	getCommonProjects,
	loadingCommonProjects,
	setCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { sortUserProjects } from '../store/actions'
import { userProjectsApi } from '@root-gipro/store/api'
import { IState } from '@root-gipro/store/interfaces/state'
import sortList from '@root-gipro/modules/userProjects/models/consts/sort-list.consts'

const UserProjects: FC = () => {
	// const { policesGipro } = useSelector((state: IState) => state.public)
	const { roleId } = store.getState().auth.user
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const { name, sortName, order } = useSelector((state: IState) => state.userProjects.sort)

	const url: string = routeMatch.path.slice(1)

	useEffect(() => {
		ym('hit', '/user-projects')
	}, [])

	useEffect(() => {
		const params = {
			limit: 5,
		}
		dispatch(setCallbacks(getCommonProjects, userProjectsApi, setCommonProjects, loadingCommonProjects))
		// dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
	}, [url])

	const sort = (name: string, sortName: string, order?: boolean | undefined): void => {
		dispatch(sortUserProjects(name, sortName, order))
	}

	return (
		<>
			{roleId && roleId < 999 ? (
				<>
					<div className='user-projects__container'>
						<FilterContainer />

						<div className='projects-wrapper'>
							<HeaderWrapper>
								<UserProjectsNav sort={sort} sortList={sortList} />
							</HeaderWrapper>
							<List />
						</div>
					</div>
				</>
			) : (
				<WrapperInformation>
					<Text>Gipro "Расчетный модуль".</Text>
					<Text>
						Вы безошибочно рассчитаете предел цены реализации инвестиционного проекта по методике с применением
						укрупненных нормативов цены (УНЦ).
					</Text>
					<TextLink href={`https://${process.env.REACT_APP_ENV}/tariffs`}>Получить доступ</TextLink>
				</WrapperInformation>
			)}
		</>
	)
}

export default UserProjects
